import React, { useEffect, useState } from "react";
import TopNote from "../../Note/TopNote";
import BottomNote from "../../Note/BottomNote";
import PanelHead from "../../Panel/Panel";
import AttributeGroupName from "./attributeGroupName";
import AttributeList from "./attributeList";
import RadioAttributes from "./radioAttributes";
import Button from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { selectAttributes } from "./attributeFunctions";
import { BLOCK_SHOW } from "../../../redux/globaltypes";
const Attribute = ({ block, attribute }) => {
  const { blockToShow, blockList, selectedOptions } = useSelector(
    (state) => state.dataPack
  );
  const [index, setIndex] = useState(0);
  const [Show, setShow] = useState(true);
  const [subAttr, setSubAttr] = useState(false);
  const unique = `${block.block_type}_block_${block.id}`;
  const [attributeType,setAttributeType]=useState(false)
  const dispatch = useDispatch();
  const selectAttribute = (item) => {
    if (!item && selectedOptions[block?.data_type]) {
      item = selectedOptions[block?.data_type];
    }
    selectAttributes(
      item,
      dispatch,
      blockToShow,
      blockList,
      block,
      selectedOptions
    );
    setShow(!Show);
  };
  useEffect(() => {
    setAttributeType(true)
    if (!attribute) {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...blockToShow, block.id],
      });
    }
    return () => {};
  }, []);
  return !attribute ? null : (
    <div className={unique}>
      <div className="panel panel-default">
        <PanelHead setShow={setShow} show={Show} selected={""} block={block} />
        <div className="panel-body">
          <div className={`panel-body-content ${!Show ? "hideMe" : ""}`}>
            <TopNote notes={block.top_note} />
            <div className="row clearfix conf-color-selection">
              <AttributeGroupName
                setSubAttr={setSubAttr}
                attribute={attribute}
                setGroup={setIndex}
                func={selectAttribute}
              />
            </div>
            <div className="row clearfix conf-color-selection">
              {!attribute?.attribute_group_list[index]
                ?.attribute_group_image ? (
                <AttributeList
                  unique={unique}
                  setSubAttr={setSubAttr}
                  selectedGroup={attribute?.attribute_group_list[index]}
                  func={selectAttribute}
                />
              ) : null}
            </div>
            <div className="row clearfix conf-color-selection">
              {subAttr ||
              attribute?.attribute_group_list[index]?.attribute_group_image ? (
                <RadioAttributes
                  func={selectAttribute}
                  unique={unique}
                  attribute={
                    subAttr ||
                    attribute?.attribute_group_list[index]?.attribute_value_list
                  }
                  showRect={block.block_title === "Select Seat Color"}
                  extraClass={attribute.attribute_type}
                />
              ) : null}
            </div>
            <BottomNote notes={block.bottom_note} />
            <Button
              disabled={!selectedOptions[block.data_type]}
              func={selectAttribute}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attribute;

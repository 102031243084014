export const constants = {
  LOGO_IGNORE: ["LS"],
  CUSTOMIZASTION: { PLAINMATS: "PLAINMATS", PERSONALIZED: "PERSONALIZED" },
  PRODUCT_TYPE: { TILED: "product-tiled", LIST: "product-list" },
};

export const LETTERING_THREAD = "Lettering Thread";
export const SEAT_POCKET = "Seat Pocket";
export const CONSOLE_COVER = "Console Cover";
export const LUMBAR_SUPPORT = "Lumbar Support";
export const SEAT_HEATER = "Seat Heater";
export const LOGO_THREAD = "Logo Thread";
export const HEEL_PAD = "Heel Pad";

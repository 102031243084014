import {
  BLOCK_SHOW,
  GET_CART_INFO,
  SELECTED_OPTIONS,
} from "../../../redux/globaltypes";
/**
 *
 *
 * @param {*} item
 * @param {*} attributes
 * @param {*} dispatch
 * @param {*} blockToShow
 * @param {*} block
 * @param {*} selectedOptions
 * @param {*} ignore
 * @param {*} attribute
 * @return {*}
 */
export const selectAttributes = (
  item,
  attributes,
  dispatch,
  blockToShow,
  block,
  selectedOptions,
  ignore,
  attribute,
  blockList
) => {
  let newBlockToShow = blockToShow;
  let BlockToShow = newBlockToShow.findIndex(
    (element) => element === block.conditional_block
  );
  if (parseInt(block.id) === blockList.length - 1) {
    if (blockToShow.includes(block.id)) blockToShow.pop();
    let newBlockToShow = blockToShow;
    dispatch({ type: BLOCK_SHOW, payload: [...newBlockToShow, block.id] });
  } else {
    let newBlock =
      BlockToShow === -1
        ? blockToShow
        : newBlockToShow.slice(0, BlockToShow + 1);
    dispatch({ type: BLOCK_SHOW, payload: [...newBlock, block.id] });
  }
  let newBlock = newBlockToShow.slice(0, BlockToShow + 1);
  let newSelectedOptions = {};
  for (let newKeys of Object.keys(selectedOptions)) {
    if (newKeys === block.data_type) break;
    newSelectedOptions = {
      ...newSelectedOptions,
      [newKeys]: selectedOptions[newKeys],
    };
  }
  dispatch({
    type: SELECTED_OPTIONS,
    payload: newSelectedOptions,
  });
  dispatch({
    type: GET_CART_INFO,
    payload: false,
  });
  if (attributes) {
    //   extracting logo attribute
    /**
     * here i have built a logo variable which will also be used as seat cover
     */
    let logo = attribute.attribute_group_list.filter(
      (item) => item.attribute_value_list
    )[0];
    /**for block type logo  */

    if (item.attribute_value_note == "Choose Color") {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...newBlock, `${block.id}=LogoChooseColor`],
      });
    }
    if (
      (item.attribute_value_note === "Color Fixed" ||
        item.attribute_value_note === "Fixed Color" ||
        !ignore?.includes(item.attribute_group_code)) &&
      !(item.attribute_value_note == "Choose Color")
    ) {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...newBlock, `${block.id}=Logo`],
      });
    }

    /**block_type for seat cover */
    if (
      ["seat-heater", "lumbar-support", "seat-pocket"].includes(
        block.block_type
      )
    ) {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...newBlock, `${block.id}`],
      });
      dispatch({
        type: SELECTED_OPTIONS,
        payload: {
          ...newSelectedOptions,
          [block.data_type]: {
            ...item,
            seat: logo?.attribute_group_title || logo?.attribute_type,
          },
        },
      });
      return;
    }

    dispatch({
      type: SELECTED_OPTIONS,
      payload: {
        ...newSelectedOptions,
        Logo: {
          ...item,
          logo: logo?.attribute_group_title || logo?.attribute_type,
        },
      },
    });
  }
  if (ignore?.includes(item.attribute_group_code)) {
    if (item.attribute_group_code === "PERSONALIZED") {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...newBlock, `${block.id}=Personalized`],
      });
    }
    if (item.attribute_group_code === "PLAINMATS") {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...newBlock, `${block.id}=PlainMats`],
      });
    }
    if (item.attribute_group_code === "PLAINCOVERS") {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...newBlock, `${block.id}=PlainCovers`],
      });
    }

    return dispatch({
      type: SELECTED_OPTIONS,
      payload: {
        ...newSelectedOptions,
        [block.data_type]: { ...item, logo: item.attribute_group_code },
      },
    });
  }
};

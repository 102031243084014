import { getEtailersOptions } from "../../../utils/include";

let checkoutParamsArr = [
  "product_line_code",
  "part_number_list",
  "brand_code",
  "etailer_id",
  "year",
  "make",
  "make_name",
  "model",
  "base_vehicle_id",
  "part_number_list_with_note",
  "vehicle_uniques",
  "product_uniques",
  "premium_binding",
  "binding_value",
  "binding_id",
  "binding_price",
  "logo",
  "sub_product_string",
  "logo_id",
  "logo_price",
  "logo_thread",
  "logo_thread_id",
  "logo_thread_color",
  "logo_description",
  "personalized",
  "cost_per_line",
  "font_name",
  "font_case",
  "first_line_text",
  "second_line_text",
  "lettering_thread_id",
  "lettering_thread_value",
  "typestyle_id",
  "typestyle_value",
  "color_id",
  "color_value",
  "embroidery_type",
  "custom_binding",
  "embroidery_lettering_type_style",
  "leather_patch_id",
  "leather_patch_value",
  "wholesale_pricing_flag",
  "binding_type",
  "embroidery_lettering_type_style_value_id",
  "product_price_list",
  "attribute_list",
  "apikey",
  "domainName",
  "shopperId",
  "sid",
  "post_back_url",
  "directionalselector_id",
  "directionalselector_value",
  "heel_pad_id",
  "heel_pad_value",
  "lettering_cost_per_line",
  // "seat_pocket",
  // "seat_pocket_group",
  // "seat_pocket_id",
  // "seat_pocket_price",
  // "seat_pocket_description",
  // "lumbar_support",
  // "lumbar_support_group",
  // "lumbar_support_id",
  // "lumbar_support_price",
  // "lumbar_support_description",
  // "seat_heater",
  // "seat_heater_group",
  // "seat_heater_id",
  // "seat_heater_price",
  // "seat_heater_description",
  "lumbar_support",
  "lumbar_support_description",
  "lumbar_support_group",
  "lumbar_support_id",
  "lumbar_support_price",
  "seat_heater",
  "seat_heater_description",
  "seat_heater_group",
  "seat_heater_id",
  "seat_heater_price",
  "seat_pocket",
  "seat_pocket_description",
  "seat_pocket_group",
  "seat_pocket_id",
  "seat_pocket_price",
  "console_cover_id",
  "console_cover_value",

];

/**
 * @params object that has been sent as a payload to get-cart-info
 * @returns object for checkout-cart
 */
export const checkoutParams = (obj) => {
   checkoutParamsArr=[...checkoutParamsArr,getEtailersOptions(false)]
   console.log(checkoutParamsArr)
  for (let key in obj) {
    if (checkoutParamsArr.includes(key)) {
      if (obj[key] === undefined || obj[key] === false) {
        delete obj[key];
      }
    } else {
      delete obj[key];
    }
  }
  return obj;
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  blockList,
  getInfo,
  getYearList,
} from "./redux/actionCreator/actionCreator";

/** global react router dom component */
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NotFound from "./components/NotFound";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import Configure from "./views/configure";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    /** getting the brand details*/
    dispatch(getInfo());
    /** getting the block list */
    dispatch(blockList());
    /**getting year list */
    dispatch(getYearList());
  }, [dispatch]);
  return (
    <div className="App">
      <ReactNotification />
      <Configure />
    </div>
  );
}

export default App;

import React from "react";

const NestedSelect = ({ options, setSelect, data, index }) => {
  return !options ? null : (
    <select
      className="conf-form-select conf-marb-10 "
      id="product_unique_PG_null_16_000_iqc42g_1"
      onChange={(e) => setSelect(e.target.value, options.filter(item=>item!==e.target.value), index)}
    >
      <option value="">Product Feature</option>
      {options.map((item) => (
        <option key={item}>{item}</option>
      ))}
    </select>
  );
};

export default NestedSelect;

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/actions/actionCreators";
import ListModal from "../list/modal";

const Modal = () => {
  const { showModal } = useSelector((state) => state.dataPack);
  const dispatch = useDispatch();
  return showModal.display ? (
    <>
      <div className="modal-backdrop fade  in"></div>
      <div
        id="modalClose"
        tabIndex="-1"
        role="dialog"
        className="modal fade in popupModel"
        size="md"
        index="0"
        animate="animate"
        onClick={() => dispatch(closeModal())}
        style={{ display: "block" }}
      >
         {showModal.listview?<ListModal/>:<div
          className="modal-dialog modal-lg "
          style={{
            display: "block",
            margin: "auto",
            marginTop: "10px",
          }}
        >
          <div className="modal-content">
            <div className="cofig-tech option-group-popup">
              <div className="container-fluid">
                <div className="conf-att-popup">
                  <div className="conf-att-popup-heading">
                    <div className="clearfix">
                      <h4 className="pull-left">
                        {showModal.imgData[1]?.attribute_value_name||showModal.imgData[1]?.attribute_value_description}
                      </h4>
                      <span className="pull-right">
                        <a
                          className="conf-close"
                          onClick={() => dispatch(closeModal())}
                        ></a>
                      </span>
                    </div>
                  </div>
                  <div className="panel-body-content">
                    <div className="row clearfix conf-marb-10 conf-color-selection">
                      <div className="col-xs-12">
                        <div className="conf-img-pop-res-container">
                          <div className="img-contain">
                            <div className="img-shiv">

                              <img src={showModal.imgData[0]} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="conf-text-border conf-marb-10 top">
                      <strong>{showModal.imgData[1]?.attribute_value_section}</strong>
                    </p>
                    <p className="conf-text-border conf-marb-10">{showModal.imgData[1]?.attribute_value_note}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>{" "}
    </>
  ) : null;
};

export default Modal;
export const initialState = {
    queryString: false,
    processing: false,
    brandDetails: false,
    block_type: false,
    block_order: false,
    yearList: false,
    makeList: false,
    modelList: false,
    lastApiPayload: false,
    vehicleUnique: false,
    vehicleUniqueList: false,
    productList: false,
    attributeList: false,
    nestedVehicle: false,
    universalFlag: false,

    blockToShow: false,
    selectedOptions: {
        selectedProducts:false,
        selectedProductsIdentity:false,
        selectedPartNumbers: false,
        selectedPartNumberwithNotes: false,
        selectedPartNumberName: false,
        Color: false,
        Logo: false,
    },
    showModal: false,
    cartInfo: false,
    accordian: JSON.stringify([]),
};

import { 
    BLOCK_LIST, 
    BRAND_LIST_INFO, 
    LOADER, 
    MAKE_LIST, 
    MODEL_LIST, 
    YEAR_LIST,
    SELECTED_OPTIONS ,
    SET_QUERYSTRING,
    VEHICLE_UNIQUE,
    PRODUCT_LIST,
    ATTRIBUTE_LIST,
    GET_CART_INFO,
    LAST_API_PAYLOAD,
    SHOW_MODAL,
    CHECKOUT_CART,
    SHOW_PRODUCT_MODAL,
    LIST_VIEW_DATA,
    ACCORDIAN_LIST
} from "./types";

import Promise from "axios";
import { NotificationManager } from "react-notifications";
import { store } from "react-notifications-component";

const headers = {
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};


// intercepts request at first and run the middleware function for reference see axios interceptors
// Promise.interceptors.request.use(function(config){

//     let formData = new FormData()
//     formData.append('domainName', window?.Configurator.domain);
//     formData.append('product_line_code', window?.Configurator.productLineCode);
//     formData.append('brand_code', window?.Configurator.brandCode);
//     formData.append('apikey', window?.Configurator?.apiKey);
//     formData.append('snippet_data_sid', window?.Configurator?.etailerPostBackParameters?.sid);
//     formData.append('sid', window?.Configurator?.etailerPostBackParameters?.sid);
//     formData.append('postBackUrl', window?.Configurator?.postBackUrl);

//     for (const property in config?.data) {
//         formData.append(property, config?.data[property]);
//     }
//     config.data=formData
//     return config

// })
/**
 * Get Brand list
 *
 * @param {*} NA
 *
 * @return {*}
 */
export const getBrandInfo = (data) => (dispatch) => {
    /** processing start */
    processing(dispatch, true);

    Promise.post(process.env.REACT_APP_API_URL + `get-info`, data, headers)
      .then((response) => {
      
      dispatch({
          type: BRAND_LIST_INFO,
          payload: response.data,
      });
          /** procesing false */
          processing(dispatch, false);
      })
      .catch((error) => {
          /** error message */
          warning(error?.response?.data?.message);
          /** procesing false */
          processing(dispatch, false);
      });
};

/**
 * Get Year List
 *
 * @param {*} NA
 *
 * @return {*}
 */
export const getYearList = (data) => (dispatch) => {
  /** processing start */
  processing(dispatch, true);
  
  Promise.post(process.env.REACT_APP_API_URL + `year/list`, data, headers)
    .then((response) => {
      
      dispatch({
        type: YEAR_LIST,
        payload: response.data,
      });
      /** procesing false */
      processing(dispatch, false);
    })
    .catch((error) => {
      /** error message */
      warning(error?.response?.data?.message);
      /** procesing false */
      processing(dispatch, false);
    });
};

/**
 * Get Year List
 *
 * @param {*} NA
 *
 * @return {*}
 */
export const getBlockList = (data) => (dispatch) => {
  /** processing start */
  processing(dispatch, true);

  Promise.post(
    process.env.REACT_APP_API_URL + `block/list`,
    data
  )
    .then((response) => {
      dispatch({
        type: BLOCK_LIST,
        payload: response.data.data,
      });
      /** procesing false */
      processing(dispatch, false);
    })
    .catch((error) => {
      /** error message */
      warning(error?.response?.data?.message);
      /** procesing false */
      processing(dispatch, false);
    });
};

/**
 * Get Make List
 *
 * @param {*} selected year
 *
 * @return {*}
 */
 export const getMakeList = (data)=>(dispatch) => {
    /** processing start */
    processing(dispatch, true);
    Promise.post(
      process.env.REACT_APP_API_URL + `make/list`,
      data,
      headers
    )
      .then((response) => { 
        dispatch({
          type: MAKE_LIST,
          payload: response?.data?.data,
        });
        /** procesing false */
        processing(dispatch, false);
      })
      .catch((error) => {
        /** error message */
        warning(error?.response?.data?.message);
        /** procesing false */
        processing(dispatch, false);
      });
  };

/**
 * Get model List
 *
 * @param {object} data
 * @return {*}
 */
export const getModelList = (data)=>(dispatch) => {
    /** processing start */
    processing(dispatch, true);
    Promise.post(
            process.env.REACT_APP_API_URL + `model/list`,
            data,
            headers
    )
    .then((response) => {
      dispatch({
        type: MODEL_LIST,
        payload: response.data.data,
      });
      dispatch({
        type: LAST_API_PAYLOAD,
        payload: data,
      });
      /** procesing false */
      processing(dispatch, false);
    })
    .catch((error) => {
      /** error message */
      warning(error?.response?.data?.message);
      /** procesing false */
      processing(dispatch, false);
    });
};

/**
 * Get unique vehicle  List
 *
 * @param {object} data
 * @return {*}
*/
export const getVehicleUnique = (data)=>(dispatch) => {
    /** processing start */
    processing(dispatch, true);
    Promise.post(
        process.env.REACT_APP_API_URL + `vehicle-unique`,
        data,
        headers
    )
    .then((response) => {
        dispatch({
            type: VEHICLE_UNIQUE,
            payload: response?.data,
        });
        /** procesing false */
        processing(dispatch, false);
    })
    .catch((error) => {
        /** error message */
        warning(error?.response?.data?.message);
        /** procesing false */
        processing(dispatch, false);
    });
};

/**
 * Get unique vehicle  List
 *
 * @param {object} data
 * @return {*}
*/
export const getProductList = (data)=>(dispatch) => {
    /** processing start */
    
    processing(dispatch, true);
    Promise.post(
        process.env.REACT_APP_API_URL + `product/list`,
        data,
        headers
    )
    .then((response) => {
        dispatch({
            type: PRODUCT_LIST,
            payload: response?.data,
        });
        /** procesing false */
        processing(dispatch, false);
    })
    .catch((error) => {
        /** error message */
        warning(error?.response?.data?.message);
        /** procesing false */
        processing(dispatch, false);
    });
};

/**
 * Get product attribute  List
 *
 * @param {object} data
 * @return {*}
*/
export const getProductAttrbuteList = (data)=>(dispatch) => {
    /** processing start */
    processing(dispatch, true);
    Promise.post(
        process.env.REACT_APP_API_URL + `attribute/list`,
        data,
        headers
    )
    .then((response) => {
        dispatch({
            type: ATTRIBUTE_LIST,
            payload: response?.data,
        });
        /** procesing false */
        processing(dispatch, false);
    })
    .catch((error) => {
        /** error message */
        warning(error?.response?.data?.message);
        /** procesing false */
        processing(dispatch, false);
    });
};

/* Get 'GET CART INFO'
 * @info called for tiled presentations only;
 * @param {*} selected @param {*} base_vehicle_id
 * @param {*} if(vehicle_unique)vehicle_unique_response_list)
 * @param {*}part_number_list,
 * @param {*}part_number_list_with_note
 *
 * @return {*}
 */
export const getCartInfo = (data) => (dispatch) => {
  // this data object is for just testing purpose
  
  /** processing start */
  processing(dispatch, true);
  Promise.post(process.env.REACT_APP_API_URL + `get-cart-info`, data, headers)
    .then((response) => {
      dispatch({
        type: GET_CART_INFO,
        payload: response.data.data,
      });
      dispatch({
        type: LAST_API_PAYLOAD,
        payload: data,
      });
      /** procesing false */
      processing(dispatch, false);
    })
    .catch((error) => {
      /** error message */
      warning(error?.response?.data?.message);
      /** procesing false */
      processing(dispatch, false);
    });
};

/* Get 'GET CART INFO'
 * @info called for tiled presentations only;
 * @param {*} selected @param {*} base_vehicle_id
 *
 * @return {*}
 */
export const checkoutCart = (data) => (dispatch) => {
  // this data object is for just testing purpose
  
  /** processing start */
  processing(dispatch, true);
  Promise.post(process.env.REACT_APP_API_URL + `checkout-cart`, data, headers)
      .then((response) => {
          dispatch({
            type: CHECKOUT_CART,
            payload: response.data.data,
          });
          
          submitFormData(response.data.data)
          /** procesing false */
          processing(dispatch, false);
      })
      .catch((error) => {
          /** error message */
          warning(error?.response?.data?.message);
          /** procesing false */
          processing(dispatch, false);
      });
};
/**
 * @params check OrderConfirmation.jsx for parameters 
 * @params @type {Object}
 * 
 */
export const checkoutAction=(data)=>(dispatch)=>
{
  /** processing start */
  processing(dispatch, true);
  Promise.post(process.env.REACT_APP_API_URL + `checkout-cart`, data, headers)
    .then((response) => {
      let a={...response.data.data}
      if(a.submitFormFlag)
      {
      let form=document.getElementById("postBackForm")
      for (let i in a) {
        if(i=="postBackUrl") continue
     for(let j in a[i])
     {
         let c=document.createElement("input")
         c.setAttribute("name",i+'[]')
         c.setAttribute("type","hidden")
         c.setAttribute("value",a[i][j])
         form.appendChild(c)
     }
    }  
    for(let i in data)
    {
    
          let c=document.createElement("input")
          c.setAttribute("name",i)
          c.setAttribute("type","hidden")
          c.setAttribute("value",data[i])
          form.appendChild(c)
    }
    form.action=a.postBackUrl
    form.submit()
  }
      /** procesing false */
      processing(dispatch, false);
    })
    .catch((error) => {
      /** error message */
      warning(error?.response?.data?.message);
      /** procesing false */
      processing(dispatch, false);
    });     
}




/**
 * alert notification component
 *
 * @param {string} title
 * @param {string} message
 */
const success = (message, title) => {
  NotificationManager.success(title ? title : "Success", message);
};

/**
 * alert notification component
 *
 * @param {string} message
 * @param {string} title
 */
const warning = (message, title) => {
  NotificationManager.warning(title ? title : "Error", message);
};

/**
 * alert notification component
 *
 * @param {string} message
 * @param {string} title
 */
export const notification = (message, title, type) => {

  store.addNotification({
    title : title, message: message, type: type,
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 500000,
      showIcon: true
    }
  });
};

/**
 * loader component
 *
 * @param {function} dispatch
 * @param {boolean} action
 */
export const processing = async (dispatch, action) => {
  dispatch({
    type: LOADER,
    payload: action,
  });
};

/**
 * store selected options
 * 
 * @param {object} selectedOption 
 * @returns 
 */
export const setOptionsSelected = (selectedOption) => dispatch => {

    dispatch({
        type: SELECTED_OPTIONS,
        payload: selectedOption
    })
}

/**
 * store selected options
 * 
 * @param {object} selectedOption 
 * @returns 
 */
 export const setDefaultQueryString = (queryString) => dispatch => {

    dispatch({
        type: SET_QUERYSTRING,
        payload: queryString
    })
}


export const showModal=(imgData)=>(dispatch)=>{
  if(Array.isArray(imgData))
  {
    dispatch({type:SHOW_MODAL,payload:{display:true,imgData}})
  }
  else{
    dispatch({type:SHOW_MODAL,payload:{display:true,listview:imgData.list,imgData}})
 
  }
}

export const setListViewData=(listViewData)=>(dispatch)=>
{
  dispatch({
    type:LIST_VIEW_DATA,
    payload:listViewData
  })
}

export const optionalProduct=(imgData)=>(dispatch)=>{
  dispatch({type:SHOW_PRODUCT_MODAL,payload:{display:true,imgData}})
}

export const closeModal=()=>(dispatch)=>{
  dispatch({type:SHOW_MODAL,payload:false})
}

const submitFormData = (data) => {

    try {
        if(data.submitFormFlag)
        {
          let form=document.getElementById("postBackForm")
          for (let key in data) {
            // if(key=="postBackUrl" || key == "sid" || key == "snippet_data_sid"){ 

            //   continue
            // }
            if( Array.isArray(data[key])) 
            {
              for(let value in data[key])
              {
                let input=document.createElement("input")
                input.setAttribute("name",key+'[]')
                input.setAttribute("type","hidden")
                input.setAttribute("value",data[key][value])
                form.appendChild(input)
              }
            }
            else{
              let input=document.createElement("input")
              input.setAttribute("name",key)
              input.setAttribute("type","hidden")
              input.setAttribute("value",data[key])
              form.appendChild(input)
            }
          }  
        // document.getElementById('sidData').value = data.sid
        form.action=data.postBackUrl
        form.submit()
      }
    } catch (error) {
    }
}

export const manageAccordians = (accordian) => (dispatch) => {
  dispatch({
    type:ACCORDIAN_LIST,
    payload:accordian
  })
}

export const getEtailersOptions = (pass_sid) => {
  let arr = []
  if (window?.Configurator?.etailerPostBackParameters) {
    if (
      Object.keys(window?.Configurator?.etailerPostBackParameters)?.length > 0
    ) {
      for (let key in window?.Configurator?.etailerPostBackParameters) {
        if(pass_sid)
        {
          arr.push("snippet_data_" + key);
        }
        else{
          arr.push(key);
        }
      }
    }
  }
  return arr
}


const arrayParams = ["year", "make"];
const getCartArr = [
  "year",
  "make_id",
  "vehicle_uniques",
  "make_name",
  "model",
  "part_number_list",
  "part_number_list_with_note",
  "product_uniques",
  "product_price_list",
  "color_id",
  "color_value",
  "embroidery_type",
  "price_group_code",
  "binding_id",
  "binding_value",
  "logo",
  "logo_id",
  "logo_description",
  "logo_price",
  "logo_thread_id",
  "logo_thread_value",
  "lettering_thread_id",
  "first_line_text",
  "second_line_text",
  "typestyle_id",
  "lettering_cost_per_line",
  "leather_patch_id",
  "lettering_thread_value",
  "leather_patch_value",
  "sub_product_string",
  "base_vehicle_id",
  "heel_pad_id",
  "heel_pad_value",
  "directionalselector_id",
  "directionalselector_value",
  "console_cover_id",
  "console_cover_value",
  "seat_pocket",
  "seat_pocket_description",
  "seat_pocket_group",
  "seat_pocket_id",
  "seat_pocket_price",
  "lumbar_support",
  "lumbar_support_description",
  "lumbar_support_group",
  "lumbar_support_id",
  "lumbar_support_price",
  "seat_heater",
  "seat_heater_description",
  "seat_heater_group",
  "seat_heater_id",
  "seat_heater_price",
  "seat_pocket",
  "post_back_url",
  "wholesale_pricing_flag	",
  // for list view
  "make",
];





export const includeParams = (endPoint) => {
  switch (endPoint) {
    case "vehicle-unique":
      return ["base_vehicle_id"];
    case "product/list":
      return ["base_vehicle_id", "vehicle_unique"];
    case "attribute/list":
      return [
        "part_number_list",
        "base_vehicle_id",
        "part_number_list_with_note"
      ];
    case "black-list":
      return ["black_list_string"];
    case "get-cart-info":
      return [...getCartArr, ...getEtailersOptions(true)];
    case "checkout-cart":
      return [...getCartArr,...getEtailersOptions(false)];
    default:
      return arrayParams;
  }
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BLOCK_SHOW, SELECTED_OPTIONS } from "../../../redux/globaltypes";
import Button from "../../button";
import TopNote from "../../Note/TopNote";
import PanelHead from "../../Panel/Panel";
import RegexParser from "regex-parser";
import { postDataApi } from "../../../utils/Apis";
import { message } from "./constants";
import BottomNote from "../../Note/BottomNote";
const Lettering = ({ block, attribute }) => {
  const { blockToShow, selectedOptions } = useSelector(
    (state) => state.dataPack
  );
  const [Show, setShow] = useState(true);
  const [firstLine, setfirstLine] = useState("");
  const [secondLine, setsecondLine] = useState("");
  const [firstLineClass, setFirstLineClass] = useState(false);
  const [secondLineClass, setSecondLineClass] = useState(false);
  const dispatch = useDispatch();

  const checkBlackList = async (word) => {
    const res = await postDataApi("black-list", {
      black_list_string: word,
    });
    if (res.data.data.status === "error") {
      alert(message(word));
      return false;
    }
    return true;
  };

  const selectAttributes = async () => {
    let firstreq = await checkBlackList(firstLine);
    let secondreq = secondLine ? await checkBlackList(secondLine) : true;
    if (firstreq && secondreq) {
      dispatch({
        type: SELECTED_OPTIONS,
        payload: { ...selectedOptions, firstLine, secondLine },
      });
      dispatch({ type: BLOCK_SHOW, payload: [...blockToShow, block.id] });
      setShow(!Show);
    }
  };

  let newRegex = selectedOptions?.Typestyle?.attribute_value_regular_expression
    ? RegexParser(
        selectedOptions?.Typestyle?.attribute_value_regular_expression
      )
    : null;

  let lettering_thread = "Lettering Thread";
  const firstLineFun = (e) => {
    if (!newRegex.test(e.target.value)) {
      setFirstLineClass(true);
      if (!(firstLine.length <= 1)) return;
    }
    setfirstLine(e.target.value);
  };

  const secondLineFun = (e) => {
    if (!newRegex.test(e.target.value)) {
      setSecondLineClass(true);
      if (!(secondLine.length <= 1)) return;
    }
    setsecondLine(e.target.value);
  };

  return (
    <div className={`${block.block_type}_block_${block.id}`}>
      {blockToShow.includes(block.conditional_block) ? (
        <div className="panel panel-default">
          <PanelHead
            setShow={setShow}
            show={Show}
            selected={[selectedOptions?.firstLine, selectedOptions?.secondLine]}
            block={block}
          />
          <div className="panel-body">
            <div className={`panel-body-content ${!Show ? "hideMe" : ""}`}>
              <TopNote notes={block.top_note} />
              <div className="row clearfix ">
                <div className="col-sm-6">
                  <h4 className="conf-title-2 conf-marb-10 ng-binding">
                    {parseInt(block.character_count) >= 12
                      ? "Add Driver’s Side Lettering:"
                      : "Required 1st Line of Lettering:"}
                  </h4>
                  <input
                    className={`form-control conf-marb-10 ${
                      firstLineClass ? "shakeInput" : ""
                    }`}
                    value={firstLine}
                    name="lineOne"
                    onKeyPress={() => setFirstLineClass(false)}
                    onChange={(e) => firstLineFun(e)}
                  />
                  <h4 className="conf-title-2 conf-marb-10 ">
                    {parseInt(block.character_count) >= 12
                      ? "Add Passenger’s Side Lettering:"
                      : "Optional 2nd Line of Lettering:"}
                  </h4>

                  <input
                    className={`form-control conf-marb-10 ${
                      secondLineClass ? "shakeInput" : ""
                    }`}
                    name="lineTwo"
                    value={secondLine}
                    onKeyUpCapture={() => setSecondLineClass(false)}
                    onChange={(e) => secondLineFun(e)}
                  />
                  <p className="conf-text-border">
                    ${block.cost_per_line} Per Line
                  </p>
                </div>

                <div className="col-sm-6" id="conf-text-preview">
                  <h4 className="conf-title-2 conf-marb-10">
                    Personalization Previewer:
                  </h4>
                  <div
                    className="conf-text-preview"
                    style={{
                      backgroundImage: `url(${selectedOptions?.Color?.attribute_value_large_image})`,
                      fontFamily: `font_${selectedOptions?.Typestyle?.manufacturer_value_id}`,
                      textAlign: `center`,
                      backgroundPosition: `center center`,
                      fontSize: `45px`,
                      position: `relative`,
                      backgroundRepeat: `no-repeat`,
                      backgroundSize: `auto`,
                      color: `rgb(${
                        selectedOptions
                          ? selectedOptions[lettering_thread]
                              ?.attribute_value_rgb
                          : null
                      })`,
                    }}
                  >
                    <div id="lettering" className="texture-pad">
                      <p className="nobr line-height-normal">{firstLine}</p>
                      <p className="nobr line-height-normal">{secondLine}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                disabled={!(firstLine.length > 0)}
                func={selectAttributes}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Lettering;

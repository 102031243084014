import React from "react";

const Button = ({func,disabled}) => {
  return (
    <div className="conf-header-box-footer text-right">
      <button disabled={disabled} onClick={()=>func()} className="btn conf-btn-primary" type="button">
        Continue
      </button>
    </div>
  );
};

export default Button;

import { postDataApi } from "../../utils/Apis";
import { isEmpty } from "../../utils/helperFunctions";
import {
  ATTRIBUTE_LIST,
  BLOCK_LIST,
  BRAND_LIST_INFO,
  GET_CART_INFO,
  LAST_API_PAYLOAD,
  MAKE_LIST,
  MODEL_LIST,
  PRODUCT_LIST,
  VEHICLE_UNIQUE,
  YEAR_LIST,
  LOADER,
  SELECTED_OPTIONS,
  SET_QUERYSTRING,
  SHOW_MODAL,
  SHOW_PRODUCT_MODAL,
  ACCORDIAN_LIST,
  UNIVERSAL_FLAG,
  SELECT_VEHICLE_UNIQUE,
} from "../globaltypes";

import { store } from "react-notifications-component";

/**
 * call brand information function
 *
 * @param {*} NA
 * @returns
 */
export const getInfo = () => async (dispatch) => {
  try {
    const res = await postDataApi("get-info");
    if (res.data.data?.universal_flag == true) {
      dispatch({ type: UNIVERSAL_FLAG, payload: true });

      /** if universal flag true then call product list api */
      const response = await postDataApi("product/list", []);
      dispatch({ type: PRODUCT_LIST, payload: response.data });

      return true;
    }
    /** call brand details data API  */
    dispatch({ type: BRAND_LIST_INFO, payload: res.data.data });
  } catch (error) { }
};

/**
 * call block list data API function
 *
 * @param {*} NA
 * @returns
 */
export const blockList = () => async (dispatch) => {
  try {
    const res = await postDataApi("block/list");
    /** response 200 then start processing component */
    if (res.status === 200)
      dispatch({ type: BLOCK_LIST, payload: res.data.data });
  } catch (error) { }
};

/**
 * call year list data API function
 *
 * @param {*} NA
 * @returns
 */
export const getYearList = () => async (dispatch) => {
  try {
    dispatch({ type: MAKE_LIST, payload: false });
    dispatch({ type: MODEL_LIST, payload: false });
    dispatch({ type: VEHICLE_UNIQUE, payload: false });
    dispatch({ type: SELECT_VEHICLE_UNIQUE, payload: false });
    const res = await postDataApi("year/list");
    if (res.status === 200)
      dispatch({ type: YEAR_LIST, payload: res.data.data });
  } catch (error) { }
};

/**
 * make list data API function
 *
 * @param {array} data
 * @returns
 */
export const getMakeList = (data) => async (dispatch) => {
  /** set model and vehicle list data truncated */
  dispatch({ type: MODEL_LIST, payload: false });
  dispatch({ type: VEHICLE_UNIQUE, payload: false });
  dispatch({ type: MAKE_LIST, payload: false });
  dispatch({ type: SELECT_VEHICLE_UNIQUE, payload: false });

  /** make list data pass to store and add to last api payload */
  const res = await postDataApi("make/list", data);
  console.log(data, res.data);
  if (res.data.data.length <= 1) {
    dispatch(
      getModelList({
        ...data,
        makeName: res.data.data[0].makeName,
        make: res.data.data[0].id,
      })
    );
    dispatch({
      type: LAST_API_PAYLOAD,
      payload: {
        ...data,
        makeName: res.data.data[0].makeName,
        make: res.data.data[0].id,
      },
    });
    dispatch({ type: MAKE_LIST, payload: res.data.data });
  } else {
    dispatch({ type: LAST_API_PAYLOAD, payload: data });
    dispatch({ type: MAKE_LIST, payload: res.data.data });
  }
};

/**
 * model list data API function
 *
 * @param {array} data
 * @returns
 */
export const getModelList = (data) => async (dispatch) => {
  try {
    /** set model and vehicle list data truncated */
    dispatch({ type: MODEL_LIST, payload: false });
    dispatch({ type: VEHICLE_UNIQUE, payload: false });
    dispatch({ type: SELECT_VEHICLE_UNIQUE, payload: false });

    /** make list data pass to store and add to last api payload */
    const res = await postDataApi("model/list", data);
    if (res.data.data.length <= 1) {
      dispatch(
        getVehicleUnique({
          ...data,
          modelName: res.data.data[0].modelName,
          base_vehicle_id: res.data.data[0].baseVehicleId,
        })
      );
      dispatch({ type: MODEL_LIST, payload: res.data.data });
      dispatch({
        type: LAST_API_PAYLOAD,
        payload: { ...data, modelName: res.data.data[0].modelName, base_vehicle_id: res.data.data[0].baseVehicleId },
      });
    } else {
      dispatch({ type: MODEL_LIST, payload: res.data.data });
      dispatch({ type: LAST_API_PAYLOAD, payload: data });
    }
  } catch (error) { }
};

/**
 * get vehicle unique list data API function
 *
 * @param {array} data
 * @returns
 */
export const getVehicleUnique = (data) => async (dispatch) => {
  try {
    /** set vehicle list data truncated */
    dispatch({ type: VEHICLE_UNIQUE, payload: false });
    dispatch({ type: SELECT_VEHICLE_UNIQUE, payload: false });

    const res = await postDataApi("vehicle-unique", data);
    let vehicleUnique = res.data;
    console.log(vehicleUnique, "sakldjasdjlks", data);
    /** vehicle unique data pass to store and add to last api payload */
    dispatch({ type: VEHICLE_UNIQUE, payload: vehicleUnique });
    dispatch({ type: LAST_API_PAYLOAD, payload: data });
  } catch (error) { }
};

/**
 * get product data API function
 *
 * @param {array} data
 * @returns
 */
export const getProductList = (data) => async (dispatch) => {
  try {
    const res = await postDataApi("product/list", data);
    let product_list = res.data.data.product_list.sort(compare);

    dispatch({
      type: PRODUCT_LIST,
      payload: { data: { ...res.data.data, product_list } },
    });
  } catch (error) { }
};

/**
 * get attribute data list API
 *
 * @param {arrar} data
 * @returns
 */
export const getAttributeList = (data) => async (dispatch) => {
  try {
    const res = await postDataApi("attribute/list", data);
    dispatch({
      type: ATTRIBUTE_LIST,
      payload: res.data.data.attributes.attribute_type_list,
    });
  } catch (error) { }
};

/**
 * get cart info API function added
 *
 * @param {array} data
 * @returns
 */
export const getCartInfo = (data) => async (dispatch) => {
  try {
    const res = await postDataApi(`get-cart-info`, data);
    if (res.status === 200) {
      dispatch({
        type: GET_CART_INFO,
        payload: res.data.data,
      });
    }
  } catch (error) { }
};

/**
 * checkout function
 *
 * @param {array} data
 * @returns
 */
export const checkoutCart = (data) => async (dispatch) => {
  /** processing start */
  // processing(dispatch, true);
  console.log("hello this is working")
  if (window?.Configurator?.postBackUrl) {
    data.post_back_url = window.Configurator.postBackUrl;
  }
  for (let key in data) {
    if (data[key] == undefined || data[key] == false) {
      delete data[key]
    }
  }
  console.log(data,"THIS IS ABOVE DATA")
  const res = await postDataApi("checkout-cart", data);
  console.log(res,"THIS IS RESPONSE")
  if (res.status === 200) {
    if (window?.Configurator?.etailerPostBackParameters) {
      if (
        Object.keys(window?.Configurator?.etailerPostBackParameters)?.length > 0
      ) {
        for (let key in window?.Configurator?.etailerPostBackParameters) {
          data[key] =window?.Configurator?.etailerPostBackParameters[key];
        }
      }
    }
    console.log(data,"THIS  IS DATA")
    submitFormData({ ...res.data.data, ...data, });
  }
};

/**
 * loader component
 *
 * @param {function} dispatch
 * @param {boolean} action
 */
export const processing = async (dispatch, action) => {
  dispatch({
    type: LOADER,
    payload: action,
  });
};

/**
 * alert notification component
 *
 * @param {string} message
 * @param {string} title
 */
export const notification = (message, title, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 500000,
      showIcon: true,
    },
  });
};

/**
 * store selected options
 *
 * @param {object} selectedOption
 * @returns
 */
export const setOptionsSelected = (selectedOption) => (dispatch) => {
  dispatch({
    type: SELECTED_OPTIONS,
    payload: selectedOption,
  });
};

/**
 * store selected options
 *
 * @param {object} selectedOption
 * @returns
 */
export const setDefaultQueryString = (queryString) => (dispatch) => {
  dispatch({
    type: SET_QUERYSTRING,
    payload: queryString,
  });
};

/**
 * model box open close redux structure
 *
 * @param {*} imgData
 * @returns
 */
export const showModal = (imgData) => (dispatch) => {
  if (Array.isArray(imgData)) {
    dispatch({ type: SHOW_MODAL, payload: { display: true, imgData } });
  } else {
    dispatch({
      type: SHOW_MODAL,
      payload: { display: true, listview: imgData.list, imgData },
    });
  }
};

/**
 * get optional product details
 *
 * @param {object} imgData
 * @returns
 */
export const optionalProduct = (imgData) => (dispatch) => {
  dispatch({ type: SHOW_PRODUCT_MODAL, payload: { display: true, imgData } });
};

/**
 * close model box event
 *
 * @param {*} NA
 * @returns
 */
export const closeModal = () => (dispatch) => {
  dispatch({ type: SHOW_MODAL, payload: false });
};

/**
 * submit form data with required params
 *
 * @param {formData} data
 * @return
 */
const submitFormData = (data) => {
  const CONFIG = {
    APP_CONTAINER: window?.Configurator?.container,
  }
  let form = document.getElementById("postBackForm");
    //build data
    const buildData = (data) => {
      console.log(data)
      for (let key in data) {
        if (Array.isArray(data[key])) {
          for (let value in data[key]) {
            let input = document.createElement("input");
            input.setAttribute("name", key + "[]");
            input.setAttribute("type", "hidden");
            input.setAttribute("value", data[key][value]);
            form.appendChild(input);
          }
        } else {
          let input = document.createElement("input");
          input.setAttribute("name", key);
          input.setAttribute("type", "hidden");
          input.setAttribute("value", data[key]);
          form.appendChild(input);
        }
      }
    }


    //CUSTOM EVENT POLYFILL
    try {
      new CustomEvent("test");
    } catch (e) {
      var CustomEvent = function (event, params) {
        var evt;
        params = params || {
          bubbles: false,
          cancelable: false,
          detail: undefined
        };

        evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      };

      CustomEvent.prototype = window.Event.prototype;
      window.CustomEvent = CustomEvent; // expose definition to window
    }


    // responseMsg = "\n" + data;
    var ctiEvent = new CustomEvent("addToCartCompleted",
      {
        detail:
        {
          message: 'Call to AddToCart api is success'
        }
      }
    );
    document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);

    //if postbackUrl present then submit url else redirect
    if (typeof data.submitFormFlag !== "undefined" &&
      data.submitFormFlag !== "" &&
      data.submitFormFlag !== "undefined" &&
      data.submitFormFlag === true) {


      if (typeof data.ajaxSubmit !== "undefined" &&
        data.ajaxSubmit !== "" &&
        data.ajaxSubmit !== "undefined" &&
        data.ajaxSubmit === true) {
        var jsonData = '<div id="jsonData" style="visibility:hidden">'
          + JSON.stringify(data) + '</div>';
        //replace form with json data hidden div
        form.parentElement.innerHTML = jsonData;

        // dispatch the addToCartCompleted event
        document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);
      }
      else if (typeof data.sendMessageFlag !== "undefined" &&
        data.sendMessageFlag !== "" &&
        data.sendMessageFlag !== "undefined" &&
        data.sendMessageFlag === true) {
        var jsonData = JSON.stringify(data);
        var targetWindow = window.parent;
        targetWindow.postMessage(jsonData, "*");
        // dispatch the addToCartCompleted event
        document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);
      }
      else if (typeof data.shopifyFlag !== "undefined" &&
        data.shopifyFlag !== "" &&
        data.shopifyFlag !== "undefined" &&
        data.shopifyFlag === "true") {
        // build the postBackForm, but don't submit it
        buildData(data)
        // dispatch the addToCartCompleted event
        document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);
      } else if (typeof data.bigCommerceFlag !== "undefined" &&
        data.bigCommerceFlag !== "" &&
        data.bigCommerceFlag !== "undefined" &&
        data.bigCommerceFlag === "true") {

        buildData(data)

        // dispatch the addToCartCompleted event
        document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);
      } 
      if(data.submitFormFlag) {

        buildData(data)
    
        form.action = data.postBackUrl;
        form.submit();
        form.reset();
        // dispatch the addToCartCompleted event
        document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);
      }
    }

 else {
  // console.log('redirect');
  // dispatch the addToCartCompleted event
  console.log(ctiEvent)
  document.getElementById(CONFIG.APP_CONTAINER).dispatchEvent(ctiEvent);
  if (typeof CONFIG.REDIRECT_URL !== "undefined" && CONFIG.REDIRECT_URL !== "" && CONFIG.REDIRECT_URL !== "undefined")
    window.location = CONFIG.REDIRECT_URL;
}
  console.log("data", data,"hello this ")

  /** response data adding to form to send predefined URL */


};

/**
 * manage accordian
 *
 * @param {array} accordian
 * @returns
 */
export const manageAccordians = (accordian) => (dispatch) => {
  dispatch({
    type: ACCORDIAN_LIST,
    payload: accordian,
  });
};

/**for sorting the product List */
function compare(firstKey, secondKey) {
  if (
    parseInt(firstKey.package_content_description_sequence) <
    parseInt(secondKey.package_content_description_sequence)
  )
    return -1;
  if (
    parseInt(firstKey.package_content_description_sequence) >
    parseInt(secondKey.package_content_description_sequence)
  )
    return 1;
  return 0;
}

import React from "react";

const BottomNote = ({ notes }) => {
  return notes?.map((note) => (
    <p className="conf-text-border border-top border-bottom-none mar-b0 bold" key={note.text}>
      {note.text}
    </p>
  ));
};

export default BottomNote;

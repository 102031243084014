import {
  ATTRIBUTE_LIST,
  BLOCK_LIST,
  BRAND_LIST_INFO,
  LAST_API_PAYLOAD,
  MAKE_LIST,
  MODEL_LIST,
  PRODUCT_LIST,
  SELECTED_OPTIONS,
  SELECT_VEHICLE_UNIQUE,
  VEHICLE_UNIQUE,
  BLOCK_SHOW,
  YEAR_LIST,
  GET_CART_INFO,
  SHOW_MODAL,
  CHECKOUT_CART,
  SHOW_PRODUCT_MODAL,
  LIST_VIEW_DATA,
  ACCORDIAN_LIST,
  NESTED_VEHICLE,
  UNIVERSAL_FLAG,
  RESET_STATE,
  UNIQUE_PRODUCT_ARR,
  SELECTED_LOGO,
} from "../globaltypes";

/** common helper instance */
import Helper from "./../../components/helper";

/** constants instance */
import { constants } from "./../../components/constants";

/** helper class */
let helper = new Helper();

const initialState = {
  queryString: false,
  constants: constants,
  helper: helper,
  processing: false,
  brandDetails: false,
  block_type: false,
  block_order: false,
  yearList: false,
  makeList: false,
  modelList: false,
  lastApiPayload: false,
  vehicleUnique: false,
  vehicleUniqueList: false,
  productList: false,
  attributeList: false,
  nestedVehicle: false,
  universalFlag: false,
  selectedOption: {
    selectedYear: false,
    selectedMake: false,
    selectedMakeName: false,
    selectedModel: false,
    selectedVehicle: false,
    services: false,

    selectedProductLineCode: false,
    selectedProductLinePrice: false,

    /** product color and image url */
    selectedManufacturerValueId: false,
    selectedAttributeValueName: false,
    selectedAttributeUrl: false,

    selectedAttributeList: false,

    matColorConfirm: false,
    confirmProductLineCode: false,

    selectedVehicleAndAttributes: false,

    /** Embrroidery steps*/
    selectedLogo: false /** slected logo type */,
    selectedLogoId: false,

    /** selected logo thread */
    selectedLogoThread: false,
    selectedLogoThreadId: false,
    selectedLogoThreadBg: false,

    /** selected logo style */
    selectedLogoStyle: false,
    selectedLogoStyleId: false,

    /** selected logo style text */
    selectedLogoStyleText1: "",
    selectedLogoStyleText2: "",

    applyEmbroidery: false,
    applyProductEmbroidery: [],
    applyEmbroideryString: false,

    /** product binding type and details */
    binding: false,
    bindingValueName: false,
    bindingValuePrice: false,
    bindingValueId: false,

    letteringCostPerLine: "",
    attributeList: "",

    /** Order Confirmation */
    orderConfirmation: false,
  },
  blockToShow: false,
  selectedOptions: {
    selectedProducts: false,
    selectedProductsIdentity: [],
    Color: false,
    Logo: false,
  },
  showModal: false,
  cartInfo: false,
  accordian: JSON.stringify([]),
  uniqueProductsArr: {},
  selectedLogo: false,
};
/**
 *
 *
 * @param {*} [state=initialState]
 * @param {*} action
 * @return {*}
 */
export const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNIVERSAL_FLAG:
      return {
        ...state,
        universalFlag: action.payload,
      };
    /**Last Api payload */
    case LAST_API_PAYLOAD:
      return {
        ...state,
        lastApiPayload: { ...action.payload },
      };
    /** brand list store */
    case BRAND_LIST_INFO:
      return {
        ...state,
        brandDetails: action.payload,
      };

    /** Year list store */
    case YEAR_LIST:
      return {
        ...state,
        yearList: action.payload,
      };

    /** block list store */
    case BLOCK_LIST:
      return {
        ...state,
        blockList: action.payload,
        block_order: action.payload,
        presentationType: action.payload[1].block_type,
      };
    case BLOCK_SHOW:
      return {
        ...state,
        blockToShow: action.payload,
      };
    /**Make List store */
    case MAKE_LIST:
      return {
        ...state,
        makeList: action.payload,
      };

    case MODEL_LIST:
      return {
        ...state,
        modelList: action.payload,
      };
    case VEHICLE_UNIQUE:
      return {
        ...state,
        vehicleUniqueList: action.payload,
      };
    case SELECT_VEHICLE_UNIQUE:
      if (!action.payload || !state.vehicleUniqueList)
        return {
          ...state,
          vehicleUnique: false,
        };
      if (state.vehicleUnique) {
        if (state.vehicleUnique.split("|").includes(action.payload))
          return state;
        return {
          ...state,
          vehicleUnique: `${state.vehicleUnique.split("|")[0]}|${
            action.payload
          }`,
        };
      }
      return {
        ...state,
        vehicleUnique: action.payload,
      };
    case NESTED_VEHICLE:
      return {
        ...state,
        nestedVehicle: action.payload,
      };
    case PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
      };
    case SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: action.payload,
      };
    case ATTRIBUTE_LIST:
      return {
        ...state,
        attributeList: action.payload,
      };
    /** Cart info store */
    case GET_CART_INFO:
      return {
        ...state,
        cartInfo: action.payload,
      };

    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
        showProductModal: action.payload,
      };

    case CHECKOUT_CART:
      return {
        ...state,
        chckoutCart: action.payload,
      };

    case SHOW_PRODUCT_MODAL:
      return {
        ...state,
        showProductModal: action.payload,
      };

    case LIST_VIEW_DATA:
      return {
        ...state,
        listViewData: action.payload,
      };

    case ACCORDIAN_LIST:
      return {
        ...state,
        accordian: JSON.stringify(action.payload),
      };
    /**Reset whole state */
    case RESET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case UNIQUE_PRODUCT_ARR:
      return {
        ...state,
        uniqueProductsArr: action.payload,
      };
    case SELECTED_LOGO:
      return {
        ...state,
        selectedLogo: action.payload,
      };

    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopNote from "../../Note/TopNote";
import PanelHead from "../../Panel/Panel";
import AttributeGroupName from "../attribute/attributeGroupName";
import Button from "../../button";
import RadioLogo from "./radioLogo";
import { selectAttributes } from "./attributeFunc";
import {
  NFL,
  NO_POCKETS,
  PERSONALIZED,
  PLAINMATS,
  PLAINCOVERS,
} from "./constants";
import { BLOCK_SHOW, SELECTED_LOGO } from "../../../redux/globaltypes";

/**
 * @info if attributes has sub attributes then the @important {subAttributes state} will be used there are some special cases only
 * @param {*} param0
 * @returns
 */

const Logo = ({ block, attribute }) => {
  const { blockToShow, selectedOptions, blockList } = useSelector(
    (state) => state.dataPack
  );
  const unique = `${block.block_type}_block_${block.id}`;
  const ignore = [PLAINMATS, PERSONALIZED, NO_POCKETS, NFL, PLAINCOVERS];
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [Show, setShow] = useState(true);
  const [oldAttributes, setOldAttributes] = useState(false);

  const [subAttr, setSubAttr] = useState(
    attribute?.attribute_group_list &&
      !ignore.includes(
        attribute?.attribute_group_list[index]?.attribute_group_title
      )
      ? attribute?.attribute_group_list[0]
      : []
  );
  const [subAttributes, setSubAttributes] = useState(
    attribute?.attribute_group_list
      ? attribute?.attribute_group_list[0]?.attribute_sub_group_list?.length >=
          1 &&
        !ignore.includes(
          attribute?.attribute_group_list[index]?.attribute_group_title
        ) &&
        block?.data_type === "Logo"
        ? attribute?.attribute_group_list[subIndex]?.attribute_sub_group_list
        : []
      : []
  );

  const dispatch = useDispatch();
  const setAtrributesFunction = (item, attributes) => {
    let oldSelect = false;
    if (item && subAttr && !ignore.includes(item.attribute_group_code)) {
      //selects the logo title for get cart info
      dispatch({
        type: SELECTED_LOGO,
        payload: attribute?.attribute_group_list[index]?.attribute_group_title,
      });
      if (block?.block_type == "seat-pocket") {
        item.seatPocket =
          attribute?.attribute_group_list[index]?.attribute_group_title;
      }
      item.attribute_group_code = subAttr.attribute_group_code;
    }
    if (!item && selectedOptions[block?.data_type]) {
      item = selectedOptions[block?.data_type];
      if (item && subAttr && !ignore.includes(item.attribute_group_code)) {
        dispatch({
          type: SELECTED_LOGO,
          payload:
            attribute?.attribute_group_list[index]?.attribute_group_title,
        });
        item.attribute_group_code = subAttr.attribute_group_code;
        oldSelect = true;
        attributes = oldAttributes;
      }
    }
    setOldAttributes(attributes);
    selectAttributes(
      item,
      attributes,
      dispatch,
      blockToShow,
      block,
      selectedOptions,
      ignore,
      attribute,
      blockList
    );
    if (ignore.includes(item.attribute_group_code) || attributes || oldSelect)
      return setShow(!Show);
  };
  useEffect(() => {
    if (!attribute?.attribute_group_list) {
      dispatch({
        type: BLOCK_SHOW,
        payload: [...blockToShow, block.id],
      });
    }
    return () => {};
  }, []);

  return !attribute?.attribute_group_list ? null : (
    <div className={unique}>
      {blockToShow.includes(block.conditional_block) ? (
        <div className="panel panel-default">
          <PanelHead
            setShow={setShow}
            show={Show}
            selected={""}
            block={block}
          />
          <div className="panel-body">
            <div className={`panel-body-content ${!Show ? "hideMe" : ""}`}>
              <TopNote notes={block.top_note} />
              <div className="row clearfix conf-color-selection">
                <AttributeGroupName
                  attribute={attribute}
                  unique={unique}
                  ignore={ignore}
                  setGroup={setIndex}
                  setSubAttr={setSubAttr}
                  func={setAtrributesFunction}
                />
              </div>
              {/* if an attribute has attribute_sub_group list we have to render those also  */}

              {!ignore.includes(
                attribute?.attribute_group_list[index]?.attribute_group_title
              ) ? (
                <h3 className="conf-title-2">{`Choose ${attribute?.attribute_group_list[index]?.attribute_group_title} Logo`}</h3>
              ) : null}

              <div className="row clearfix conf-color-selection">
                <AttributeGroupName
                  unique={unique}
                  ignore={ignore}
                  setGroup={setSubIndex}
                  setSubAttr={setSubAttr}
                  func={setAtrributesFunction}
                  subAttributes={subAttributes}
                />
              </div>

              <div className="row clearfix conf-color-selection">
                {subAttr &&
                (subAttr?.attribute_value_list ||
                  subAttr[0]?.attribute_sub_group_list ||
                  subAttr) &&
                attribute?.attribute_group_list[index]
                  ?.attribute_group_image ? (
                  <>
                    {!ignore.includes(
                      attribute?.attribute_group_list[index]
                        ?.attribute_group_title
                    ) &&
                    subAttributes[subIndex] &&subIndex? (
                      <h3 className="conf-title-2">{`Choose ${subAttributes[subIndex]?.attribute_group_title} Logo`}</h3>
                    ) : null}
                    <RadioLogo
                      unique={unique}
                      attribute={
                        (subAttributes && subIndex
                          ? subAttributes[subIndex]?.attribute_value_list
                          : false) ||
                        subAttr?.attribute_value_list ||
                        subAttr
                      }
                      func={setAtrributesFunction}
                    />
                  </>
                ) : null}
              </div>

              {subAttr?.attribute_value_list ? (
                <div className="licance-logo-txt clearfix col-centered ">
                  <img
                    className="img-responsive"
                    src={
                      subAttr
                        ? subAttr?.attribute_value_list[0]
                            ?.attribute_value_license_image
                        : subAttr[0]?.attribute_value_license_image
                    }
                    alt=""
                  />
                </div>
              ) : null}
              {ignore.includes(selectedOptions?.Logo?.logo) ? null : (
                <Button
                  disabled={!selectedOptions[block.data_type]}
                  func={setAtrributesFunction}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Logo;

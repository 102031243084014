import React from "react";
import RadioAttributes from "./radioAttributes";
import SimpleAttributes from "./simpleAttributes";
/**
 * @info this is a reusable components for @block type of @attribute
 * can aslo be used for @block_type @logo
 * @generally used for @attribute_sub_group_list @attribute_value_list of a @selectedGroup
 *
 * @param {*} unique, is unique for every attribute @contains block number and block_type
 *
 *
 * @returns
 */
const AttributeList = ({ selectedGroup, unique, setSubAttr,func }) => {
  return (
    !selectedGroup?.attribute_group_image?
    selectedGroup?.attribute_sub_group_list?.length > 0 ? (
        selectedGroup?.attribute_sub_group_list.map((item, index) => (
          <SimpleAttributes
            setSubAttr={setSubAttr}
            unique={unique}
            key={index+unique}
            attribute={item}
          />
        ))
      ) : selectedGroup?.attribute_value_list?.length > 0 ? (
          <RadioAttributes
            unique={unique}
            attribute={selectedGroup?.attribute_value_list}
            func={func}
          />
        ) : null
      :null
  );
};

export default AttributeList;

import { useSelector } from "react-redux";

/** Vehicle list view details */
import ListView from './view'

/**
 * product list details
 *  
 * @params {*} NA
 * @returns 
 */
const List = () => {

    const { productList } = useSelector(state => state.dataPack)
    
    return (

        <div className="config-tabbed">
            {
                productList &&
                <div className="container-fluid brd-btm"><ListView /></div>
            }
        </div>
    )
}

export default List
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import './styles/index.css'
window.Configurator = {
  init: function (options) {
    for (let i in options) {
      window.Configurator[i] = options[i];
    }
  }
};
window.addEventListener('load', () => {

    var link = document.createElement('link');
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = process.env.REACT_APP_CSS_PATH;
    document.head.appendChild(link);
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>{" "}
        </React.StrictMode>,
        document.getElementById(window?.Configurator?.container)
    )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const giveData = (list) => {
  let newList = list.map((el) => el.split("|"));
  let rootObject = {};

  for (let rootEl = 0; rootEl <= newList.length - 1; rootEl++) {
    let childObj = newList[rootEl];
    for (let childEl = 0; childEl <= childObj.length - 1; childEl++) {
      if (rootObject[childObj[childEl]]) {
        let c = new Set([
          ...rootObject[childObj[childEl]],
          childObj[childEl + 1],
        ]);
        let values = c.values();
        c = Array.from(values);
        rootObject[childObj[childEl]] = c;
        continue;
      }
      if (!childObj[childEl + 1]) continue;
      if (!rootObject[childObj[childEl]])
        rootObject[childObj[childEl]] = [childObj[childEl + 1]];
    }
  }

  return rootObject;
};

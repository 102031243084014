import React, { useState } from "react";
import { useSelector } from "react-redux";
import TopNote from "../../Note/TopNote";
import PanelHead from "../../Panel/Panel";
import SubProductSelector from "./subProductSelector";
import Button from "../../button";
import { useDispatch } from "react-redux";
import { BLOCK_SHOW, SELECTED_OPTIONS } from "../../../redux/globaltypes";

/**
 * @use this component is used mainly for @apply_embroidery block
 * @use for selecting sub products on which embroidery is to be done
 * @contains checkBoxes as components
 * @param {*} param0
 * @returns
 */



const Subproduct = ({ block, attribute }) => {
  const { attributeList, blockToShow, selectedOptions } = useSelector(
    (state) => state.dataPack
  );
  const unique = `${block.block_type}_block_${block.id}`;
  const [Show, setShow] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const dispatch = useDispatch();
  const whenToshow = () => {
    let all_conditions = block.conditional_block.split("||");
    for (let i of all_conditions) {
      if (blockToShow.includes(i)) return true;
    }
  };
  const exceptionEtailer = window?.Configurator.apiKey === "BfzsxSZ269jOTr7bf84UUlKJbldJ6YIZ"

  const setAttributes = () => {

    setShow(!Show);
    dispatch({ type: BLOCK_SHOW, payload: [...blockToShow, block.id] });
    dispatch({
      type: SELECTED_OPTIONS,
      payload: {
        ...selectedOptions, [block.block_type]: exceptionEtailer ? attributeList[0].sub_product_data.map(item => `${item.part_number}@${item.package_content_description}`
        ) : selectedProduct
      },
    });
  };


  const addSubProduct = (item, action) => {
    if (selectedProduct) {
      if (selectedProduct.includes(item) && action === "remove") {
        let newSelectedProduct = selectedProduct.filter((str) => str !== item);
        setSelectedProduct(newSelectedProduct);
        return;
      }
      setSelectedProduct([...selectedProduct, item]);
      return;
    }
    setSelectedProduct([item]);
  };

  return (
    <div className={unique}>
      {whenToshow() ? (
        <div className="panel panel-default">
          <PanelHead
            setShow={setShow}
            show={Show}
            selected={""}
            block={block}
          />
          <div className="panel-body">
            <div className={`panel-body-content ${!Show ? "hideMe" : ""}`}>
              <TopNote notes={block.top_note} />
              <SubProductSelector func={addSubProduct} exceptionEtailer={exceptionEtailer} />
              <Button
                disabled={selectedProduct?.length <= 0}
                func={setAttributes}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Subproduct;

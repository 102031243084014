import { useState } from "react";
//import { Loader } from "";
import MainImage from "./MainImage";
const Slider = ({ images, showSlider = false }) => {
  const [CurrentImage, setCurrentImage] = useState(images ? images[0] : null);
  const [Val, setVal] = useState(0);
  const [Next, setNext] = useState(images?.length > 4 ? "" : "disabled");
  const [Prev, setPrev] = useState("disabled");

  function nextButton() {
    if (-(55 * (images.length - 4)) >= Val) {
      setNext("disabled");
    } else {
      setPrev("");
      setVal(Val - 55);
    }
  }
  function prevButton() {
    if (0 <= Val) {
      setPrev("disabled");
    } else {
      setVal(Val + 55);
      setNext("");
    }
  }


  return (
    <div className="col-sm-5" id="plcImageSlider">
      <div className="image-list-wrapper">
        {images ? <MainImage image={CurrentImage ? CurrentImage : images[0]} /> : <MainImage image={'images/ajax-loader.gif'} />}
        {!showSlider > 0 && images?.length > 0 ? (
          <div className="config-gallery-thumb">
            <div className="image-container">
              <div className="carousel-frame">
                <span id="bx-prev">
                  <a
                    className={`bx-prev ${Prev}`}
                    href=""
                    onClick={prevButton}
                  ></a>
                  <a
                    className={`config-gal-prev ${Prev}`}
                    onClick={prevButton}
                  ></a>
                </span>
                <div className="bx-wrapper" style={{ minWidth: "220px" }}>
                  <div
                    className="bx-viewport"
                    aria-live="polite"
                    style={{
                      width: `${(images.length + 2) * 55}px`,
                      display: "flex",
                      flexWrap: "no-wrap",
                      height: "49px",
                    }}
                  >
                    <ul
                      className="
                  config-gallery-slide
                  bxslider
                  "
                      id="bxSlider"
                      bx-slider=""
                      style={{
                        position: "relative",
                        transitionDuration: " 0.5s",
                        transform: `translate3d(${Val}px,0px,0px)`,
                      }}
                    >
                      {images.map((url, index) => {
                        return (
                          <li
                            onClick={() => setCurrentImage(images[index])}
                            bx-slider-item=""
                            key={url}
                            style={{
                              cursor: "pointer",
                              float: "left",
                              listStyle: "none",
                              position: "relative",
                              width: "50px",
                              marginRight: "5px",
                            }}
                            aria-hidden="false"
                          >
                            <div
                              className="
                        gallery-big-img-thumb
                        "
                            >
                              <div className="img-contain">
                                <div className="img-shiv">
                                  {url.includes("youtube.com") ? (
                                    <img
                                      src={`https://img.youtube.com/vi/${url.split("=")[1]
                                        }/0.jpg`}
                                    />
                                  ) : (
                                    <img src={url || "images/ajax-loader.gif"} />
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="bx-controls"></div>
                </div>
                <span id="bxr-next">
                  <a className={`bx-next ${Next}`} href=""></a>
                  <a
                    className={`config-gal-next ${Next}`}
                    onClick={() => nextButton()}
                  ></a>
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Slider;

import React from "react";
import { useSelector } from "react-redux";
import Modal from "../app/modules/configure/modal";
import Header from "../components/Block/header/header";
import Config from "../modules/config-tab";

const Configure = () => {
  const { universalFlag, showModal } = useSelector((state) => state.dataPack);
  return (
    <div className="config-tech margin-bottom22">
      <h2 className="conf-title">{}</h2>
      {!universalFlag ? <Header /> : null}
      <Config />
      {showModal.display ? <Modal /> : null}
    </div>
  );
};

export default Configure;

import React from "react";
import { useSelector } from "react-redux";

const PanelHead = ({ block, selected, setShow, show }) => {
  const { selectedOptions } = useSelector((state) => state.dataPack);
  const getHeadingList = () => {
    let keys = [
      "attribute_value_name",
      "attribute_value_description",
      "attribute_group_code",
    ];
    if (selected) return selected;
    for (let i of keys) {
      if (selectedOptions[block.data_type][i])
        return [selectedOptions[block.data_type][i]];
    }
  };
  /**
   *
   * @returns true or false depending upon whether the block is open or closed also whether there are lists
   */
  const whenToShow = () =>
    (!show && selectedOptions[block.data_type]) ||
    (!show && selected?.length > 0);
  return (
    <div
      className="panel-heading clearfix expanded"
      onClick={() => setShow(!show)}
    >
      <div className="row">
        <div className="conf-prod-fil-items conf-font-dark col-sm-8">
          <h4 className="panel-title">
            <a>
              <div className="clearfix">
                <span className="pull-left">{block.block_title}</span>
              </div>
            </a>
          </h4>
          {whenToShow() ? (
            <ul className="conf-list-normal font-size-small mar-0 ">
              {getHeadingList()?.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          ) : null}
        </div>
        <p className="col-sm-4 text-right conf-change-link">
          <a>
            <span className="pull-right conf-edit">Change</span>{" "}
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default PanelHead;

import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actionCreator/actionCreator";

const RadioLogo = ({ attribute, unique, func }) => {
  const dispatch = useDispatch();
  return Array.isArray(attribute)
    ? attribute?.map((item, index) => (
        <div className="col-xs-6 col-sm-3" key={index}>
          <div className="conf-embroid-one">
            <div className="conf-embroid-content">
              <div className="conf-img-container">
                {" "}
                <img
                  alt="not available"
                  className="img-responsive"
                  src={
                    item.attribute_value_small_image ||
                    item.attribute_value_large_image ||
                    item.attribute_group_image
                  }
                  onClick={() =>
                    dispatch(
                      showModal([
                        item?.attribute_value_small_image ||
                          item?.attribute_value_large_image ||
                          item?.attribute_group_image,
                        item
                      ])
                    )
                  }
                />
              </div>
            </div>
            <div className="conf-color-blk-selection">
              <label>
                <span className="select">
                  <input
                    type="radio"
                    id={item.attribute_value_sequence}
                    name={unique}
                    group={unique}
                    value={item.attribute_value_note}
                    onClick={() => func(item, attribute)}
                  />
                </span>
                <span className="conf-label-value logo-v-mdl ">
                  ${item.attribute_value_price} <br />
                  <i>{item.attribute_value_note}</i>
                </span>
              </label>
            </div>
          </div>
        </div>
      ))
    : null;
};

export default RadioLogo;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMakeList,
  getModelList,
  getProductList,
  getVehicleUnique,
} from "../../redux/actionCreator/actionCreator";
import { SELECT_VEHICLE_UNIQUE } from "../../redux/globaltypes";
import FilterSelect from "../Select/FilterSelect";

const Filter = () => {
  const {
    lastApiPayload,
    yearList,
    makeList,
    modelList,
    vehicleUnique,
    vehicleUniqueList,
    nestedVehicle,
  } = useSelector((state) => state.dataPack);
  const dispatch = useDispatch();
  const disableOrNot = () => {
    if(!modelList) return true
    if (lastApiPayload?.modelName && !vehicleUniqueList) return false;
    if(vehicleUnique)
    {
      if(Object.keys(vehicleUniqueList[vehicleUnique.split('|')[0]]).length<=0)
      return false
    }
    if (vehicleUniqueList) {
      if(Object.keys(vehicleUniqueList).length===0)return false
      if (Object.keys(vehicleUniqueList).length >= 1) {
        for (let i of Object.keys(vehicleUniqueList)) {
          if (Object.keys(vehicleUniqueList[i]).length > 1 && vehicleUnique) {
            if (vehicleUnique) {
              if (vehicleUnique.includes("|")) {
                return false;
              }
            }
          }
        }
      }
    }

    return true;
  };
  return (
    <div className="col-sm-6">
      <div>
        <div className="conf-header-box">
          <div className="conf-header-box-title">Select Vehicle</div>
          <div className="conf-header-box-content">
            <form name="form" id="navigatorForm">
              <FilterSelect
                action={getMakeList}
                disabled={false}
                defaultOption="Year"
                data={yearList}
                keyName="year"
              />
              <FilterSelect
                action={getModelList}
                disabled={!makeList}
                defaultOption="Make"
                data={makeList}
                value="id"
                keyName="make"
                text="makeName"
              />
              <FilterSelect
                action={getVehicleUnique}
                disabled={!modelList}
                defaultOption="Model"
                data={modelList}
                keyName="base_vehicle_id"
                value="baseVehicleId"
                text="modelName"
              />
              {vehicleUniqueList&&Object.keys(vehicleUniqueList).length>=1 && (
                <FilterSelect
                  action={{ type: SELECT_VEHICLE_UNIQUE }}
                  disabled={!vehicleUniqueList}
                  defaultOption="Feature"
                  data={Object.keys(vehicleUniqueList || {})}
                  keyName="vehicleUnique"
                />
              )}
              {nestedVehicle&&vehicleUnique ? (
                <FilterSelect
                  action={{ type: SELECT_VEHICLE_UNIQUE }}
                  disabled={!nestedVehicle}
                  defaultOption="Feature"
                  data={nestedVehicle}
                  keyName="vehicleUnique"
                />
              ) : null}
            </form>
          </div>
          <div className="conf-header-box-footer text-right">
            <button
              disabled={disableOrNot()}
              onClick={() =>
                dispatch(
                  getProductList({
                    ...lastApiPayload,
                    vehicle_unique: vehicleUnique,
                  })
                )
              }
              className="btn conf-btn-primary"
              type="button"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import {
  checkoutCart,
  getCartInfo,
} from "../../../redux/actionCreator/actionCreator";
import BottomNote from "../../Note/BottomNote";
import TopNote from "../../Note/TopNote";
import PanelHead from "../../Panel/Panel";

import { giveProductDetails } from "../product/giveProductDetails";
import { checkoutParams } from "./checkoutParams";
import {
  NFL,
  NO_POCKETS,
  PERSONALIZED,
  PLAINCOVERS,
  PLAINMATS,
} from "../logo/constants";
import { logoPayloads } from "./payloadConditions";
import { capitalize } from "../../../utils/helperFunctions";
var collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

const OrderConfirmation = ({ block }) => {
  const [Show, setShow] = useState(true);
  const [showComponent, setShowComponent] = useState(false);
  const ignore = [PLAINMATS, PERSONALIZED, NO_POCKETS, NFL, PLAINCOVERS];
  const {
    blockToShow,
    cartInfo,
    vehicleUnique,
    selectedOptions,
    lastApiPayload,
    uniqueProductsArr,
    selectedLogo,
  } = useSelector((state) => state.dataPack);
  const {
    product_price_list,
    part_number_list,
    part_number_with_note,
    selected_product_name,
    selected_product_unique,
  } = giveProductDetails(selectedOptions?.selectedProducts, true);

  const whenToshow = () => {
    let all_conditions = block.conditional_block.split("||");
    for (let i of all_conditions) {
      if (blockToShow.includes(i)) return true;
    }
  };
  const getLogo = () => {
    if (selectedOptions?.Logo) {
      return ["personalized", "plainmats", "plaincovers"].includes(
        selectedOptions?.Logo?.logo?.toLowerCase()
      )
        ? selectedOptions?.Logo?.logo?.toLowerCase()
        : "logo";
    }
    return "";
  };
  /** @type {*} */
  const orderConfirmationData = {
    // attribute_list: (JSON.parse(selectedOptions?.attributeList))?.join('|'),
    /** start */
    // attribute_list: "|color|logo_thread|leather_patch|binding",
    part_number_list: part_number_list?.sort(collator.compare)?.join(),
    year: lastApiPayload?.year,
    make_id: lastApiPayload?.make,
    make: lastApiPayload?.make,
    make_name: lastApiPayload?.makeName,
    model: lastApiPayload?.modelName,
    base_vehicle_id: lastApiPayload?.base_vehicle_id,
    vehicle_uniques: vehicleUnique,
    product_price_list: product_price_list?.sort(collator.compare)?.join(),
    product_uniques:
      uniqueProductsArr && selected_product_unique
        ? selected_product_unique?.join()
        : "",
    embroidery_type: getLogo(),
    personalized: "",
    first_line_text: selectedOptions?.firstLine,
    second_line_text: selectedOptions?.secondLine,
    sub_product_string: selectedOptions
      ? selectedOptions["sub-product-selector"]?.join()
      : null,
    lettering_cost_per_line: selectedOptions?.Typestyle?.attribute_value_price,
    part_number_list_with_note: part_number_with_note?.join(",,"),
    //   dynamic_part_number_string:
    //     selectedOptions?.cartInfo?.dynamic_part_number_string,
    //
  };
  const dispatch = useDispatch();

  function givePayloadData() {
    let orderConfirmationObject = {};

    for (let selectedAttribute in selectedOptions) {
      let property = selectedAttribute.split(" ").join("_").toLocaleLowerCase();
      if (selectedAttribute === "Logo") {
        if (!ignore.includes(selectedLogo) && selectedLogo !== false) {
          logoPayloads(
            orderConfirmationObject,
            selectedOptions,
            selectedAttribute,
            selectedLogo
          );
        } else {
          orderConfirmationObject[`${property}`] =
            selectedLogo || capitalize(getLogo());
        }
      } else {
        orderConfirmationObject[`${property}_id`] =
          selectedOptions[selectedAttribute]?.manufacturer_value_id ||
          selectedOptions[selectedAttribute]?.attribute_group_code;
        orderConfirmationObject[`${property}_value`] =
          selectedOptions[selectedAttribute]?.attribute_value_name ||
          selectedOptions[selectedAttribute]?.attribute_group_title ||
          selectedOptions[selectedAttribute]?.attribute_value_name;
        orderConfirmationObject[`${property}_description`] =
          selectedOptions[selectedAttribute]?.attribute_value_description;
        orderConfirmationObject[`${property}_price`] =
          selectedOptions[selectedAttribute]?.attribute_value_price;
        orderConfirmationObject[`${property}_group`] =
          selectedOptions[selectedAttribute]?.attribute_value_group ||
          selectedOptions[selectedAttribute]?.attribute_group_code;
        orderConfirmationObject[`${property}`] =
          selectedOptions[selectedAttribute]?.seat ||
          selectedOptions[selectedAttribute]?.seatPocket ||
          selectedOptions[selectedAttribute]?.attribute_value_description ||
          selectedOptions[
            selectedAttribute
          ]?.attribute_group_code?.toLowerCase();
      }
    }
    for (let selectedAttribute in orderConfirmationObject) {
      if (!orderConfirmationObject[selectedAttribute]) {
        delete orderConfirmationObject[selectedAttribute];
      }
    }

    return orderConfirmationObject;
  }
  let data = { ...givePayloadData(), ...orderConfirmationData };

  //adding all the elatilerPostBackParameters to the data object
  if (window?.Configurator?.etailerPostBackParameters) {
    if (
      Object.keys(window?.Configurator?.etailerPostBackParameters)?.length > 0
    ) {
      for (let key in window?.Configurator?.etailerPostBackParameters) {
        data["snippet_data_" + key] =
          window?.Configurator?.etailerPostBackParameters[key];
      }
    }
  }

  useEffect(() => {
    /** calling brand list information */

    if (whenToshow()) {
      for (let key in data) {
        if (data[key] === undefined || data[key] === false) {
          delete data[key];
        }
      }
      dispatch(getCartInfo(data));
    }
  }, [blockToShow]);
  return whenToshow() ? (
    <div className={`${block.block_type}_block_${block.id}`}>
      <div className="panel panel-default">
        <PanelHead setShow={setShow} show={Show} selected={""} block={block} />
        <div className="panel-body">
          <div className={`panel-body-content ${!Show ? "hideMe" : ""}`}>
            <TopNote notes={block.top_note} />

            {/* {
              !cartInfo &&
              <loader />
            } */}
            <div className="table-responsive border-none">
              {cartInfo && parse(cartInfo?.order_confirmation_string)}
            </div>

            <BottomNote notes={block.bottom_note} />

            <div className="conf-header-box-footer text-right">
              <button
                className="btn conf-btn-primary"
                type="button"
                onClick={() => dispatch(checkoutCart(checkoutParams(data)))}
              >
                Add To Cart
              </button>
            </div>
          </div>
          <div>
            <form id="postBackForm" method="post"></form>{" "}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default OrderConfirmation;

import {
  BLOCK_SHOW,
  GET_CART_INFO,
  SELECTED_OPTIONS,
} from "../../../redux/globaltypes";
import { BINDING, COLOR, LOGO_THREADING, TYPESTYLE } from "./constants";

/**
 *
 * @param {*} item
 * @param {*} dispatch
 * @param {*} blockToShow
 * @param {*} block
 * @param {*} selectedOptions
 * @returns
 */
export const selectAttributes = (
  item,
  dispatch,
  blockToShow,
  blockList,
  block,
  selectedOptions
) => {
  let newBlockToShow = blockToShow;
  let BlockToShow = newBlockToShow.findIndex(
    (element) => element === block.conditional_block
  );
  if (parseInt(block.id) === blockList.length - 1) {
    if (blockToShow.includes(block.id)) blockToShow.pop();
    let newBlockToShow = blockToShow;
    dispatch({ type: BLOCK_SHOW, payload: [...newBlockToShow, block.id] });
  }
  else{
    let newBlock =BlockToShow===-1?blockToShow:newBlockToShow.slice(0, BlockToShow + 1);
    dispatch({ type: BLOCK_SHOW, payload: [...newBlock, block.id] });

  }

  let newSelectedOptions = {};
  for (let newKeys of Object.keys(selectedOptions)) {
    if (newKeys === block.data_type) break;
    newSelectedOptions = {
      ...newSelectedOptions,
      [newKeys]: selectedOptions[newKeys],
    };
  }
  dispatch({
    type: SELECTED_OPTIONS,
    payload: newSelectedOptions,
  });
  dispatch({
    type: GET_CART_INFO,
    payload: false,
  });
  switch (block.data_type) {
    case COLOR:
      return dispatch({
        type: SELECTED_OPTIONS,
        payload: { ...newSelectedOptions, [block.data_type]: item },
      });
    case LOGO_THREADING:
      return dispatch({
        type: SELECTED_OPTIONS,
        payload: { ...newSelectedOptions, LetteringThread: item },
      });
    case TYPESTYLE:
      return dispatch({
        type: SELECTED_OPTIONS,
        payload: { ...newSelectedOptions, [block.data_type]: item },
      });
    case BINDING:
      return dispatch({
        type: SELECTED_OPTIONS,
        payload: { ...newSelectedOptions, [block.data_type]: item },
      });

    default:
      return dispatch({
        type: SELECTED_OPTIONS,
        payload: {
          ...newSelectedOptions,
          [block.data_type]: item,
        },
      });
  }
};

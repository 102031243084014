/**this file checks the conditions and creates payload According to that condition
 *
 */

export const logoPayloads = (
  orderConfirmationObject,
  selectedOptions,
  selectedAttribute,
  selectedLogo
) => {
  let property = selectedAttribute.split(" ").join("_").toLocaleLowerCase();
  orderConfirmationObject[`${property}_id`] =
    selectedOptions[selectedAttribute]?.manufacturer_value_id ||
    selectedOptions[selectedAttribute]?.attribute_group_code;
  orderConfirmationObject[`${property}_value`] =
    selectedOptions[selectedAttribute]?.attribute_value_name ||
    selectedOptions[selectedAttribute]?.attribute_group_title ||
    selectedOptions[selectedAttribute]?.attribute_value_name;
  orderConfirmationObject[`${property}_description`] =
    selectedOptions[selectedAttribute]?.attribute_value_description;
  orderConfirmationObject[`${property}_price`] =
    selectedOptions[selectedAttribute]?.attribute_value_price;
  orderConfirmationObject[`${property}_group`] =
    selectedOptions[selectedAttribute]?.attribute_value_group ||
    selectedOptions[selectedAttribute]?.attribute_group_code;
  orderConfirmationObject[`${property}`] = selectedLogo;
};

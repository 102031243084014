import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MainImage from "../../Slider/MainImage";
import { selectProducts } from "./selectedProductFunction";
import { showModal } from "../../../redux/actionCreator/actionCreator";
import NestedList from "./Nested";
import Checkbox from "./checkbox";
import { UNIQUE_PRODUCT_ARR } from "../../../redux/globaltypes";
const Product = ({ product }) => {
  const [note, setnote] = useState(product?.part_number_note);
  const [checked, setchecked] = useState(false);
  const { selectedOptions, uniqueProductsArr } = useSelector(
    (state) => state.dataPack
  );

  const [productUniqueString, setUniqueString] = useState("");
  const dispatch = useDispatch();
  const selectProduct = () => {
    selectProducts(
      product,
      note,
      selectedOptions,
      dispatch,
      productUniqueString
    );
    setchecked(!checked);
  };
  const setUniqueProduct = (uniqueString) => {
    setUniqueString(uniqueString);
    const note = product?.product_unique_list.filter(
      (item) => item?.product_unique_string === uniqueString
    );
    const uniquenumber = note[0]?.brand_application_code;
    dispatch({
      type: UNIQUE_PRODUCT_ARR,
      payload: {
        ...uniqueProductsArr,
        [product?.package_content_description]: uniquenumber,
      },
    });
    console.log(note[0])
    setnote(note ? note[0]?.product_unique_note : note);
    if (checked) setchecked(!checked);
  };
  return (
    <div className={product?.products_per_row === "1" ? "col-sm-12" : "col-sm-4"} style={{ paddingRight: product?.products_per_row === "1" ? "0px" : "" }}>
      <div className="conf-mat-type-item">
        <MainImage
          func={() => dispatch(showModal([product?.media_list[0], ""]))}
          image={product?.media_list[0]}
          containImg={product?.products_per_row === "1" ? true : false}
        />
        <div className="eqheight" style={{ maxHeight: "56px" }}>
          <div className="conf-mat-type-content new-content">
            <h4 className="conf-title mar-b0">
              {product?.package_content_description}
            </h4>
          </div>

          <div className="conf-inner-con">
            <p
              className="font-size-small mar-b0"
              style={{
                visibility: note?.length > 1 ? "visbile" : "hidden",
              }}
            >
              {note?.split("|")?.map((notetext, index) => (
                <em key={index}>
                  <strong>Note: </strong>
                  <span>{notetext}</span>{" "}
                </em>
              ))}
            </p>
          </div>
        </div>
        {product.product_unique_list?.length >= 1 ? (
          <NestedList
            product={product?.product_unique_list}
            setUniqueProduct={setUniqueProduct}
          />
        ) : null}
        {product?.product_unique_list?.length >= 1 ? (
          productUniqueString.length > 1 ? (
            <Checkbox
              product={product}
              controlCheck={true}
              checked={checked}
              setchecked={setchecked}
              selectProduct={selectProduct}
            />
          ) : null
        ) : (
          <Checkbox
            product={product}
            controlCheck={false}
            selectProduct={selectProduct}
          />
        )}
      </div>
    </div>
  );
};

export default Product;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeList } from "../../../redux/actionCreator/actionCreator";
import { BLOCK_SHOW } from "../../../redux/globaltypes";
import { isEmpty } from "../../../utils/helperFunctions";
import TopNote from "../../Note/TopNote";
import { giveProductDetails } from "./giveProductDetails";
import Product from "./product";

const ProductList = ({ productList: { product_list }, block }) => {
  //   here you can also sort elements
  const { selectedOptions, lastApiPayload } = useSelector(
    (state) => state.dataPack
  );
  const dispatch = useDispatch();
  const [Show, setShow] = useState(true);
  const [selectedProductList, setSelectedProductList] = useState([])
  const getAttrFun = () => {
    if (selectedOptions.selectedProducts) {
      let selectedProductList = selectedOptions?.selectedProducts;
      var { product_price_list, part_number_list, part_number_with_note, selected_product_name } = giveProductDetails(selectedProductList)
      
      var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      part_number_list=part_number_list.sort(collator.compare)
      part_number_with_note=part_number_with_note.sort(collator.compare)
      selected_product_name=selected_product_name.sort(collator.compare)
      dispatch(
        getAttributeList({
          ...lastApiPayload,
          part_number_list,
          part_number_list_with_note:part_number_with_note.join(',,')
        })
      );
      dispatch({ type: BLOCK_SHOW, payload: [block.id] });
      setSelectedProductList(selected_product_name)
      setShow(!Show);
    }
  };
  const showHideAndRmove = () => {
    // dispatch({ type: BLOCK_SHOW, payload: [block.id] });
    setShow(!Show);
  };
  const newProductList = [];
  let tempArr = [];
  for (
    let productIndex = 0;
    productIndex <= product_list.length;
    productIndex++
  ) {
    if (!product_list[productIndex]) {
      newProductList.push(tempArr);
      tempArr = [];
      break;
    }
    tempArr.push(product_list[productIndex]);

    if ((productIndex + 1) % 3 === 0) {
      newProductList.push(tempArr);
      tempArr = [];
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="panel panel-default">
          <div
            className="panel-heading clearfix"
            onClick={() => showHideAndRmove()}
          >
            <div className="row">
              <div className="conf-prod-fil-items conf-font-dark col-sm-8">
                <h4 className="panel-title">
                  <a>
                    <div className="clearfix">{block.block_title}</div>
                  </a>
                </h4>
                {!Show && selectedOptions?.selectedProducts ? (
                  <ul className="conf-list-normal font-size-small mar-0">
                    {selectedProductList.map((name, index) => (
                      <li key={name + index}>{name}</li>
                    ))}
                  </ul>
                ) : null}
              </div>
              <p className="col-sm-4 text-right conf-change-link">
                <a>
                  <span className="pull-right conf-edit">Change</span>
                </a>
              </p>
            </div>
          </div>
          <div className="panel-body">
            <div className={`panel-body-content ${!Show ? "hideMe" : ""}`}>
              <TopNote notes={block.top_note} />

              <div className="row clearfix">
                <div>
                  {newProductList?.map((products, index) => (
                    <div className="clearfix" key={index}>
                      {products?.map((product, index) => (
                        <Product
                          key={product.part_number + index}
                          product={product}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className="conf-header-box-footer text-right border-top-none">
                <button
                  type="button"
                  className="btn conf-btn-primary"
                  disabled={
                    selectedOptions?.selectedProducts === false ||
                    isEmpty(selectedOptions?.selectedProducts)
                  }
                  onClick={() => getAttrFun()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;

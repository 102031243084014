import { useState } from "react";
import NestedSelect from "../../Select/NestedSelect";
import { giveData } from "./givenestedList";

function NestedList({ product, setUniqueProduct }) {
  let list = product.map((item) => item.product_unique_string);

  const firstOptions = () => {
    return list[0].includes("|") ? [list[0].split("|")[0]] : list;
  };

  const [selectedValue, setSelectedValue] = useState([]);
  let dataList = giveData(list);
  function giveNested(value, values, index) {
    if (selectedValue.includes(value) || selectedValue.includes(values[0])) {
      const product_unique_string = new Set([...selectedValue]);
      let newArray = Array.from(product_unique_string.values());
      newArray.splice(index + 1, 1, value);
      if (value === "") {
        newArray = newArray.slice(0, index + 1);
        setUniqueProduct("")
        return setSelectedValue([...newArray]);
      }
      let modifiedNewArray = newArray;
      if (!dataList[value]) {
        modifiedNewArray = [...newArray.slice(0, index + 1), value];
      }
      setSelectedValue([...modifiedNewArray]);
      setUniqueProduct(modifiedNewArray.join("|"));
      return;
    }
    const product_unique_string = new Set([...selectedValue, value]);
    let newArray = Array.from(product_unique_string.values());
    if (value === "") {
      newArray = newArray.slice(0, index + 1);
      return setSelectedValue([...newArray]);
    }
    setSelectedValue([...newArray]);
    setUniqueProduct(newArray.join("|"));
  }
  return (
    <div className="App">
      <NestedSelect options={firstOptions()} setSelect={giveNested} />
      {selectedValue?.map((item, index) => (
        <NestedSelect
          key={item}
          options={dataList[selectedValue[index]]}
          index={index}
          setSelect={giveNested}
        />
      ))}
    </div>
  );
}

export default NestedList;

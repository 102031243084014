import axios from "axios";
import { includeParams } from "./include";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// intercepts request at first and run the middleware function for reference see axios interceptors
axios.interceptors.request.use(function (config) {
  const configuratorDetails = new URL(window?.location.href);

  let formData = new FormData();
  formData.append("brand_code", window?.Configurator.brandCode);
  formData.append("product_line_code", window?.Configurator.productLineCode);
  formData.append(
    "product_type_code",
    window?.Configurator.productTypeCode
      ? window?.Configurator.productTypeCode
      : ""
  );

  formData.append("domainName",configuratorDetails.origin);
  formData.append("apikey", window?.Configurator?.apiKey);

  for (const property in config?.data) {
    if (
      includeParams(config.url.split("api/v1/")[1]).includes(property) &&
      config?.data[property] !== false &&
      config?.data[property] !== undefined
    ) {
      formData.append(property, config?.data[property]);
    }
  }
  config.data = formData;
  return config;
});

const headers = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
};
export const postDataApi = async (url, data) => {
  const res = await axios.post(BASE_URL + url, data);
  return res;
};

import React from "react";
import { useSelector } from "react-redux";
import Modal from "../app/modules/list/modal";
import Attribute from "../components/Block/attribute/attribute";
import Lettering from "../components/Block/lettering/lettering";
import Logo from "../components/Block/logo/logo";
import OrderConfirmation from "../components/Block/order/orderConfirmation";
import ProductList from "../components/Block/product/productList";
import Subproduct from "../components/Block/subproduct/subproduct";
import ListView from "./../app/modules/list";

const Config = () => {
  const { block_order, productList, blockToShow, brandDetails } = useSelector(
    (state) => state.dataPack
  );
  const { attributeList } = useSelector((state) => state.dataPack);
  const getAttribute = (data_type) => {
    return attributeList
      ? attributeList.filter((item) => item.attribute_type === data_type)[0]
      : [];
  };

  const getFilteredLogo = (logoArray) => {
    let newLogoArray = ["clrb", "CLRB"].includes(
      window?.Configurator?.brandCode
    )
      ? logoArray?.attribute_group_list.filter(
          (item) => item.attribute_group_title !== "Lifestyle"
        )
      : logoArray?.attribute_group_list;
    return { ...logoArray, attribute_group_list: newLogoArray };
  };
  const whenToshow = (conditional_block) => {
    let all_conditions = conditional_block.split("||");
    for (let i of all_conditions) {
      if (blockToShow.includes(i)) return true;
    }
  };
  return (
    <main>
      <div className="main cofig-tech">
        <section id="demo">
          <div className="config-tabbed">
            <div className="productListBlock" id="product-tiled-scroll">
              {/* mapping block list attributes */}
              {block_order
                ? block_order.map((block) => {
                    if (block.block_type === "product-tiled" && productList) {
                      return (
                        <ProductList
                          key={block.id}
                          block={block}
                          productList={productList.data}
                        />
                      );
                    } else if (block.block_type === "product-list") {
                      return <ListView key={block.id} />;
                    }
                  })
                : null}
            </div>
            <div className="container-fluid ">
              <div className="attributeGroupBlock">
                {block_order && attributeList
                  ? block_order.map((block) => {
                      if (
                        ["attribute"].includes(block.block_type) &&
                        whenToshow(block.conditional_block)
                      ) {
                        return (
                          <Attribute
                            key={block.id}
                            attribute={getAttribute(block.data_type)}
                            block={block}
                          />
                        );
                      }
                      if (
                        [
                          "logo",
                          "seat-pocket",
                          "lumbar-support",
                          "seat-heater",
                        ].includes(block.block_type) &&
                        whenToshow(block.conditional_block)
                      ) {
                        return (
                          <Logo
                            key={block.id}
                            attribute={getFilteredLogo(
                              getAttribute(block.data_type)
                            )}
                            block={block}
                          />
                        );
                      }
                      if (
                        block.block_type === "lettering" &&
                        whenToshow(block.conditional_block)
                      ) {
                        return (
                          <Lettering
                            key={block.id}
                            attribute={getAttribute(block.data_type)}
                            block={block}
                          />
                        );
                      }
                      if (
                        block.block_type === "sub-product-selector" &&
                        whenToshow(block.conditional_block)
                      ) {
                        return <Subproduct key={block.id} block={block} />;
                      }

                      if (
                        block.block_type === "order-confirmation" &&
                        whenToshow(block.conditional_block)
                      ) {
                        return (
                          <OrderConfirmation
                            key={block.id}
                            block={block}
                            attribute={getAttribute(block.data_type)}
                          />
                        );
                      }
                    })
                  : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Config;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashRouter, Router, unstable_HistoryRouter } from "react-router-dom";
import { blockList, getInfo, getYearList } from "../../../redux/actionCreator/actionCreator";
import { initialState } from "../../../redux/reducer/initial.state";
import Filter from "../../Filter/Filter";
import Slider from "../../Slider/Slider";

import { RESET_STATE } from "./../../../redux/globaltypes";
/**
 *
 * @returns the header block with slider and filter if product list is available
 * @else returns the header block only
 */
const Header = () => {
  const dispatch = useDispatch();
  const resetState = (el) => {
    el.preventDefault();
    dispatch({ type: RESET_STATE, payload: initialState });
    /** getting the brand details*/
    dispatch(getInfo());
    /** getting the block list */
    dispatch(blockList());
    /**getting year list */
    dispatch(getYearList());
  };
  const { productList, lastApiPayload, brandDetails, vehicleUnique } =
    useSelector((state) => state.dataPack);
  return (
    <>
      <div className="headerBlock">
        <div className="cofig-tech">
          <div
            className={`row clearfix conf-header-block ${
              productList ? "hideMe" : ""
            }`}
          >
            <Slider images={brandDetails.image_list} />
            <Filter />
          </div>
          <div className={`container-fluid ${!productList ? "hideMe" : ""}`}>
            <div className="conf-prod-fil-header clearfix">
              <div className="row">
                <div className="conf-prod-fil-items conf-font-dark col-sm-8">
                  <h5>
                    <span> {lastApiPayload?.year} </span>
                    <span> {lastApiPayload?.makeName} </span>
                    <span> {lastApiPayload?.modelName} </span>
                  </h5>

                  <ul className="conf-list-normal mar-0">
                    {vehicleUnique
                      ? vehicleUnique
                          .split("|")
                          ?.map((list) => <li key={list}>{list}</li>)
                      : null}
                  </ul>
                </div>
                <p className="col-sm-4 text-right conf-change-vehicle">
                  <a
                    href="/"
                    className="conf-color-normal conf-underline font-tiny"
                    onClick={(el) => resetState(el)}
                  >
                    Change Vehicle
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

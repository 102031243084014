export const LOADER = "LOADER";
export const BRAND_LIST_INFO = "BRAND_LIST_INFO";
export const BLOCK_LIST = "BLOCK_LIST";
export const BLOCK_SHOW = "BLOCK_SHOW";
export const YEAR_LIST = "YEAR_LIST";
export const MAKE_LIST = "MAKE_LIST";
export const MODEL_LIST = "MODEL_LIST";
export const SELECTED_OPTIONS = "SELECTED_OPTIONS";
export const SET_QUERYSTRING = "SET_QUERYSTRING";
export const VEHICLE_UNIQUE = "VEHICLE_UNIQUE";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const ATTRIBUTE_LIST = "ATTRIBUTE_LIST";
export const LAST_API_PAYLOAD = "LAST_API_PAYLOAD";
export const GET_CART_INFO = "GET_CART_INFO";
export const SHOW_MODAL = "SHOW_MODAL";
export const CHECKOUT_CART = "CHECKOUT_CART";
export const SHOW_PRODUCT_MODAL = "SHOW_PRODUCT_MODAL";
export const SELECT_VEHICLE_UNIQUE = "SELECT_VEHICLE_UNIQUE";
export const LIST_VIEW_DATA = "LIST_VIEW_DATA";
export const ACCORDIAN_LIST = "ACCORDIAN_LIST";
export const NESTED_VEHICLE = "NESTED_VEHICLE";
export const UNIVERSAL_FLAG = "UNIVERSAL_FLAG";
export const RESET_STATE = "RESET_STATE";
export const UNIQUE_PRODUCT_ARR = "UNIQUE_PRODUCT_ARR";
export const SELECTED_LOGO = "SELECTED_LOGO";

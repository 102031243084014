import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actionCreator/actionCreator";

/**
 * @info this is a reusable component
 * @use can be and mostly used for block_type @attribute and @logo
 * @param {*} param0
 * @moreInfo about parameters you can refer to the @attribute and @logo component
 * @attribute is checked whether it has property @attribute_value_list or @attribute_sub_group_list
 * @returns
 */

const SimpleAttributes = ({
  attribute,
  setSubAttr,
  setGroup,
  index,
  ignore,
  func,
}) => {
  /**
   * @called when we change or click on any attribute
   * @all can vary
   */
  const changeValues = () => {
    setSubAttr(
      attribute?.attribute_value_list || attribute?.attribute_sub_group_list[0]
    );
    if (setGroup) {
      setGroup(index);
    }

    if (func && (attribute?.attribute_sub_group_list?.length <= 0||attribute?.attribute_value_list?.length<=0)) {
      return func(attribute);
    }
  };
  return (
    <div className="col-xs-6 col-sm-3 " onClick={() => changeValues()}>
      <div className="conf-embroid-one active">
        <div className="conf-embroid-head ">
          {attribute?.attribute_group_title}
        </div>
        <div className="conf-embroid-content">
          <div className="conf-img-container">
            <img
              alt="not available"
              className="img-responsive"
              src={
                attribute?.attribute_group_image ||
                attribute?.attribute_value_small_image
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleAttributes;

export const giveProductDetails = (selectedProductList, orderConfirmation) => {
  /**getting the values of particular product selected when clicked on the checkbox */
  let part_number_with_note = [];
  let part_number_list = [];
  let product_price_list = [];
  let selected_product_name = [];
  let selected_package_content_description = [];
  let selected_product_unique = [];
  /**above we have the product object of the selected product so you can extract any data */

  /**if we have same partNumbers then we need to distinguish them using a double underscore and randomstring */
  let randomChar = "__" + Math.random().toString(36).substring(7);
  for (let product in selectedProductList) {
    part_number_with_note = [
      ...part_number_with_note,
      `${selectedProductList[product].part_number}${
        orderConfirmation &&
        part_number_list.includes(selectedProductList[product].part_number)
          ? randomChar //this works only if there already exists a part_number
          : ""
      }@${selectedProductList[product]?.note?selectedProductList[product]?.note:selectedProductList[product].part_number_note}`,
    ];

    part_number_list = [
      ...part_number_list,
      `${selectedProductList[product].part_number}${
        orderConfirmation &&
        part_number_list.includes(selectedProductList[product].part_number)
          ? randomChar //this works only if there already exists a part_number
          : ""
      }`,
    ];
    product_price_list = [
      ...product_price_list,
      `${selectedProductList[product].part_number}@${selectedProductList[product].desired_retail_price}`,
    ];
    selected_product_name = [
      ...selected_product_name,
      `${selectedProductList[product].package_content_description}${
        selectedProductList[product].productUniqueString
          ? " - " + selectedProductList[product].productUniqueString
          : ""
      }`,
    ];
    if (selectedProductList[product].productUniqueString) {
      selected_product_unique = [
        ...selected_product_unique,
        `${selectedProductList[product].part_number}${
          selectedProductList[product].productUniqueString
            ? "@" + selectedProductList[product].productUniqueString
            : ""
        }`,
      ];
    }
    selected_package_content_description = [
      ...selected_package_content_description,
      `${selectedProductList[product].package_content_description}${
        selectedProductList[product].part_number_note
          ? " - " + selectedProductList[product].part_number_note
          : ""
      }`,
    ];
  }

  return {
    part_number_list,
    part_number_with_note,
    product_price_list,
    selected_product_name,
    selected_package_content_description,
    selected_product_unique,
  };
};

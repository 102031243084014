import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NESTED_VEHICLE } from "../../redux/globaltypes";
import { VEHICLE_UNIQUE, YEAR } from "./constants";

/**
 *
 *
 * @param {*} { action,defaultOption, data, disabled,text,value }
 *action:gives the reference to action creator
 *  @return {*}
 */
const FilterSelect = ({
  action,
  defaultOption,
  data,
  disabled,
  text,
  value,
  keyName,
}) => {
  const { lastApiPayload,vehicleUniqueList } = useSelector((state) => state.dataPack);
  const dispatch = useDispatch();

  const fetchOptions = (e) => {
    if (keyName === VEHICLE_UNIQUE&&e.target.value.length>0) {
      dispatch({ ...action, payload: e.target.value });
      if(vehicleUniqueList[e.target.value]?Object.keys(vehicleUniqueList[e.target.value])?.length>=1:false)
      {
        dispatch({type:NESTED_VEHICLE, payload:Object.keys(vehicleUniqueList[e.target.value]) });
      }
      return;
    }
    if (e.target.value !== defaultOption) {
      let giveAdditionalInfo = () => data?.filter(item => (item[value] === parseInt(e.target.value) || item[value] === e.target.value))[0]
      dispatch(
        action(
          lastApiPayload
            ? { ...lastApiPayload, [keyName]: e.target.value, ...giveAdditionalInfo() }
            : { [keyName]: e.target.value, ...giveAdditionalInfo() }
        )
      );
    }
  };

  return (
    <select
      onChange={fetchOptions}
      disabled={disabled}
      className="conf-form-select conf-marb-10"
    >
      <option value={defaultOption} className="">
        {defaultOption}
      </option>
      {data
        ? data?.map((item) =>
          defaultOption === YEAR || keyName === VEHICLE_UNIQUE ? (
            <option key={item} value={item} className="val">
              {item}
            </option>
          ) : (
            <option key={item[value]} value={item[value]} className="val" selected={data.length<=1}>
              {item[text]}
            </option>
          )
        )
        : null}
    </select>
  );
};

export default FilterSelect;

import React from "react";
import { BINDING, STANDARD } from "./constants";
import SimpleAttributes from "./simpleAttributes";

/**
 *
 * @param {*} attribute, setGroup, setSubAttr, unique, ignore, func
 * attribute block if there are parent attributes in the logo block
 * subAttributes
 * @returns
 * this is specifically for the LOGO block,
 *
 */
const AttributeGroupName = ({
  attribute,
  setGroup,
  setSubAttr,
  unique,
  ignore,
  func,
  subAttributes,
  block,
}) => {
  const selectAttribute = (index, item) => {
    if (item?.attribute_group_code === STANDARD) {
      setSubAttr(false);
      setGroup(index);
      return func(item);
    }
    setSubAttr(false);
    setGroup(index);
    if (func && !attribute?.attribute_group_title === BINDING) return func();
  };

  return (block
    ? attribute?.attribute_group_list?.length >= 1
    : attribute?.attribute_group_list?.length > 1) ||
    (subAttributes && subAttributes?.length > 0)
    ? (subAttributes ? subAttributes : attribute?.attribute_group_list)?.map(
        (item, index) =>
          !item?.attribute_group_image ? (
            <button
              key={item.attribute_group_title + index}
              type="button"
              className={`btn conf-btn-normal conf-attribute-group-name`}
              onClick={() => selectAttribute(index, item)}
            >
              {item.attribute_group_title}
              {ignore ? ignore[0] : ""}
              <span className="or-sep"></span>
            </button>
          ) : (
            <SimpleAttributes
              index={index}
              setGroup={setGroup}
              attribute={item}
              setSubAttr={setSubAttr}
              key={item.attribute_group_title + index}
              ignore={ignore}
              func={func}
            />
          )
      )
    : null;
};

export default AttributeGroupName;

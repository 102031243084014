import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actionCreator/actionCreator";
import { isObject } from "../../../utils/helperFunctions";

const RadioAttributes = ({ attribute, unique, func, showRect, extraClass }) => {
  const dispatch = useDispatch();
  const selectAttribute = (item) => {
    if (func) return func(item);
  };

  return Array.isArray(attribute) && attribute?.length > 0
    ? attribute.map((item, index) => (
        <div className="col-xs-6 col-sm-3" key={index + unique}>
          <div className="conf-color-blk">
            <div
              className={`conf-color-blk-header ${
                !showRect ? "imageRectangle" : ""
              }`}
            >
              <div className="conf-img-res-container ">
                <div className="img-contain">
                  <div>
                    <img
                      className="img-responsive"
                      alt="not available"
                      src={
                        item?.attribute_value_small_image ||
                        item?.attribute_value_large_image ||
                        item?.attribute_group_image
                      }
                      onClick={() =>
                        dispatch(
                          showModal([
                            item?.attribute_value_small_image ||
                              item?.attribute_value_large_image ||
                              item?.attribute_group_image,
                            item,
                          ])
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`conf-color-blk-selection ${
                ["All Caps", "Upper and-or Lower Case"].includes(
                  item.manufacturer_value_code
                )
                  ? "conf-radio-center"
                  : ""
              }`}
            >
              <label>
                <span className="select">
                  <input
                    type="radio"
                    name={unique}
                    onClick={() => selectAttribute(item)}
                  />
                </span>
                {["All Caps", "Upper and-or Lower Case"].includes(
                  item.manufacturer_value_code
                ) ? null : (
                  <span
                    className="conf-label-value"
                    style={{
                      fontSize:
                        (
                          item?.attribute_value_description ||
                          item?.attribute_value_name
                        ).length >= 24
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {item?.attribute_value_description ||
                      item?.attribute_value_name}
                  </span>
                )}
              </label>
            </div>
          </div>
        </div>
      ))
    : null;
};

export default RadioAttributes;

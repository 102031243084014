import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/actionCreator/actionCreator";

const TopNote = ({ notes }) => {
  const dispatch = useDispatch();
  return notes
    ? notes.map((note) => (
        <p
          key={note.text}
          onClick={() =>
            note?.link?.length >= 1
              ? dispatch(showModal([note.link, ""]))
              : null
          }
          className="conf-text-border border-top-none bold"
        >
          {note.text}
        </p>
      ))
    : null;
};

export default TopNote;

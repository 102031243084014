import React, { Component } from "react";
import queryString from "query-string";

export default class Index extends Component 
{
    /**
     * get product block type
     * 
     * @param {object} blockType 
     * @returns {string} 
     */
    getProductBlockType(blockType) 
    {
        if (blockType) {
            let blockData = blockType?.filter(list => list?.sequence == 2)
            return blockData ? blockData[0]?.block_type : false
        }

        return false
    }
    /**
     * get brand API params
     *
     * @param {*} NA
     * @returns
    */
    getBrandCode() 
    {
        return {
            brand_code: window.Configurator.brandCode,
            product_line_code: window.Configurator["productLineCode"],
            api_key: window.Configurator["apiKey"]
        };
    }
    /**
     * get form with default key and domain name
     *
     * @param{*} NA
     * @returns {*} NA
     */
    calDefaultParams() 
    {
        let formData = new FormData();
        formData.append("domainName", process.env.REACT_APP_STORE_DOMAIN);
        formData.append("apikey", process.env.REACT_APP_API_KEY);

        return formData;
    }
    /**
     * get brand API params
     *
     * @param {*} NA
     * @returns
     */
    getBrandParams() 
    {
        return {
            brand_code: window.Configurator.brandCode,
            product_line_code: window.Configurator["productLineCode"],
        };
    }

    /**
     * get make API params
     *
     * @param {*} NA
     * @returns
     */
    getYearParams() 
    {
        return {
            brand_code: window.Configurator.brandCode,
            product_line_code: window.Configurator["productLineCode"],
        };
    }

    /**
     * get make API params
     *
     * @param {object} queryString
     * @returns
     */
    getMakeParams() 
    {
        return {
            brand_code: window.Configurator.brandCode,
            product_line_code: window.Configurator["productLineCode"],
        };
    }

    /**
     * get make API params
     *
     * @param {object} queryString
     * @returns
     */
    getProductListParams(modelList, selectedOption) 
    {
        if (window.Configurator) {
            let modelDetails = this.findItemDetails(
                modelList,
                selectedOption?.selectedModel
            );

            return {
                brand_code: window.Configurator.brandCode,
                product_line_code: window.Configurator["productLineCode"],
                base_vehicle_id: modelDetails?.baseVehicleId,
                vehicle_unique: selectedOption?.selectedVehicle + '|' + selectedOption?.selectedVehicleAndAttributes,
            };
        }
    }

    /**
     * query string parsing
     * 
     * @param {string} handle 
     * @returns 
     */
    getUrlDecoded(handle) 
    {
        try {
            return queryString.parse(handle);
        } catch (error) { }
    }

    /**
     * year list dropdown options
     * 
     * @param {object} years 
     * @returns 
     */
    yearDropDown(years) 
    {
        try {
            if (years) {
                return years?.map((list, index) => {
                    return (
                        <option value={list} key={index}>
                            {list}
                        </option>
                    );
                });
            }
        } catch (error) { }
        return [];
    }

    /**
     * make list dropdown
     * 
     * @param {object} makeList 
     * @returns 
     */
    makeDropDown(makeList) 
    {
        try {
            if (makeList) {
                return makeList?.map((list, index) => {
                    return (
                        <option value={list?.id} key={index}>
                            {list?.makeName}
                        </option>
                    );
                });
            }
        } catch (error) { }
        return [];
    }

    /**
     * model dropdown options
     * 
     * @param {object} years 
     * @returns 
     */
    modelDropDown(years) 
    {
        try {
            if (years) {
                return years?.map((list, index) => {
                    return (
                        <option
                            value={`${list?.baseVehicleId}-${list?.modelName}`}
                            label={list?.modelName}
                            key={index}
                        >
                            {list?.modelName}
                        </option>
                    );
                });
            }
        } catch (error) { }
        return [];
    }

    /**
     * vehicle dropdown options
     * 
     * @param {object} vehicleUnique 
     * @returns 
     */
    vehicleUniqueDropDown(vehicleUnique) 
    {
        let unique = [];

        try {
            Object.keys(vehicleUnique).forEach(function (key, index) {
                unique.push(
                    <option value={key} label={key} key={index}>
                        {key}
                    </option>
                );
            });
        } catch (error) { }
        return unique;
    }

    /**
     * find vehicle object length
     * 
     * @param {object} vehicleUnique 
     * @returns 
     */
    vehicleCustomize(vehicleUnique) 
    {
        return Object.keys(vehicleUnique)?.length ? true : false;
    }

    /**
     * find product details by ID
     * 
     * @param {object} itemList 
     * @param {int} itemId 
     * @returns 
     */
    findItemDetails(itemList, itemId) 
    {
        try {
            let item = itemList?.filter(
                (list) => list?.id == parseInt(itemId) || list?.baseVehicleId === itemId
            );
            return item ? item[0] : false;
        } catch (error) { }
    }

    /**
     * find object keys
     * 
     * @param {object} vehicleUnique 
     * @returns {array}
     */
    findVehicleUniqueDetail(vehicleUnique) 
    {
        return Object.keys(vehicleUnique);
    }

    /**
     * 
     * @param {object} modelList 
     * @param {array} selectedOption 
     * @returns 
     */
    getBaseVehicleId(modelList, selectedOption) 
    {
        let index = modelList.findIndex(
            (list) => list.id == selectedOption?.selectedVehicle
        );
        return modelList[index]?.baseVehicleId || false;
    }

    /**
     * gte attributes name
     * 
     * @param {object} productAttributeList 
     * @param {string} name 
     * @returns 
     */
    getAttributesByName(productAttributeList, name) 
    {
        try {
            let { attribute_type_list } = productAttributeList?.data?.attributes;

            let attributeData = attribute_type_list?.filter(
                (list) => list?.attribute_type === name
            );

            return attributeData[0] || [];
        } catch (error) { }
    }

    /**
     * array first element
     *
     * @param {array} dataArray
     *
     * @return {string} string
     */
    firstElement(dataArray) 
    {
        try {

            return Array.isArray(dataArray) ? dataArray.values().next().value : dataArray[0];
        }
        catch (error) {
            return dataArray;
        }
    }

    /**
     * data analyzer
     * 
     * @param {object} productList 
     * @returns 
     */
    listViewDataAnalyzer(productList) 
    {
        const { product_list } = productList?.data
        const { dependent_product_list } = productList?.data
        let optionalData = []
        let listType = []
        let sequence = []
        let sequenceGroup = []
        let isRequired = false;
        let optionGroupCode = false;
        let groupingFlag = false;
        let checkoutGroupFlag = false;
        let groupCode = false

        try {

            if (Array.isArray(product_list)) {
                product_list?.map((options) => {

                    if (Array.isArray(options?.option_group_list)) {
                        options?.option_group_list?.map((option) => {

                            let dataOption = {
                                product_code: options?.part_number,
                                colorName: option?.value_name,
                                sequence: option?.value_sequence,
                                color: option?.value_copy,
                                media: option?.value_media
                            }

                            groupCode = option?.option_group_code

                            if (!optionalData[groupCode]) optionalData[groupCode] = []
                            if (!sequenceGroup[groupCode]) sequenceGroup[groupCode] = []
                            if (!sequence[groupCode]) sequence[groupCode] = 0

                            sequence[groupCode] = sequence[option?.option_group_code] ? (sequence[groupCode] > option?.value_sequence ? option?.value_sequence : sequence[groupCode]) : option?.value_sequence
                            optionalData[groupCode]?.push(dataOption)

                            sequenceGroup[groupCode][option?.value_sequence] = {
                                product_code: options?.part_number,
                                colorName: option?.value_name,
                                sequence: option?.value_sequence,
                                option_sequence: option?.option_group_sequence,
                                color: option?.value_copy,
                                media: option?.value_media
                            }

                            if (!listType?.includes(groupCode))
                                listType.push(groupCode)

                            isRequired = options?.dependent_product?.required ? true : false

                            groupingFlag = options?.option_group_list?.length ? true : false
                            checkoutGroupFlag = (options?.add_to_cart_grouping_flag == 1) ? true : false

                            if (groupCode)
                                optionGroupCode = groupCode

                        });
                    }
                })

                return { optionalData, sequence, sequenceGroup, isRequired, dependent_product_list, optionGroupCode, groupingFlag, checkoutGroupFlag, listType }
            }
        } catch (error) {
        }
        return false
    }

    /**
     * product data array format
     * 
     * @param {object} productList 
     * @param {string} partNumber 
     * @param {int} sequence 
     * @param {object} dataAnalysis 
     * @returns 
     */
    getProductFilterData(productList, partNumber, sequence, dataAnalysis) 
    {
        const { product_list } = productList?.data
        let filterData = []

        try {
            filterData = []

            if (dataAnalysis?.listType?.length) {
                dataAnalysis?.listType?.map((key) => {

                    if (!filterData[key]) filterData[key] = []

                    if (key == "PDD")
                        filterData[key] = (product_list?.filter(list => list?.option_group_list[0]?.value_sequence == sequence))

                    else
                        filterData[key] = product_list?.filter(list => (list?.dependent_product?.required || list?.option_group_list[0]?.value_sequence == partNumber))
                })
                filterData = this.getArrayMerge(filterData);
            }
            else
                filterData = product_list
        } catch (error) {
        }

        return filterData
    }
    /**
     * array merge function
     * 
     * @param {array} filterData 
     * @returns 
    */
    getArrayMerge(filterData) 
    {
        let productDataArray = []
        if (filterData) {
            let keys = Object.keys(filterData);

            keys?.map((option) => {
                filterData[option]?.map(value => {
                    productDataArray.push(value)
                })
            })
        }
        return productDataArray
    }
}

import React from "react";
// import { Loader } from "../Loader/Loader";

const MainImage = ({ image, func, containImg }) => {
  // giving out the embed url of any youtbe video
  function getembed(url) {
    let a = "https://www.youtube.com/embed/" + url.split("=")[1];

    return a;
  }
  return (
    <div className="config-product-gallery">
      <div className="gallery-big-img">
        <div className={"img-contain"} id={containImg ? "large" : ''}>
          <div className="img-shiv">
            {image?.includes("youtube.com") ? (
              <iframe
                width="100%"
                height="100%"
                src={getembed(image)}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : (
              <img src={image} onClick={() => func()} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainImage;

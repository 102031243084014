/**
 * @params Object
 * @returns
 */
export const isEmpty = (obj) => {
  if (obj) {
    if (Object.keys(obj).length === 0) return true;
  }
  return false;
};
/**
 *
 * @param {*} obj
 * @returns true or false checks whether an object or not
 */
export const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

/**
 * Capitalize String function
 *
 */

export const capitalize = (name) => {
  if (name) {
    return name[0]?.toUpperCase() + name?.slice(1);
  }
  return false;
};

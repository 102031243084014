import React from "react";

const Checkbox = ({
  product,
  selectProduct,
  checked,
  controlCheck,
  setchecked,
}) => {
  return (
    <div className="conf-mat-type-footer text-right pad-b5 new-content-footer">
      <span className="conf-price fs20">${product.desired_retail_price} </span>
      <span className="btn-select clearfix active" id="product_selected_LS2">
        <label>
          <span className="icon-checkbox">
            {controlCheck ? (
              <input
                checked={checked}
                onClick={checked === false ? selectProduct : selectProduct}
                type="checkbox"
                className="product_checkbox"
              />
            ) : (
              <input
                onClick={selectProduct}
                type="checkbox"
                className="product_checkbox"
              />
            )}
          </span>
          <span>Select</span>
        </label>
      </span>
    </div>
  );
};

export default Checkbox;

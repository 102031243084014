import React from "react";
import { useSelector } from "react-redux";
import { giveProductDetails } from "../product/giveProductDetails";

const SubProductSelector = ({ func, exceptionEtailer }) => {
  const { attributeList, selectedOptions } = useSelector(
    (state) => state.dataPack
  );
  const {
    // product_price_list,
    // part_number_list,
    // part_number_with_note,
    selected_product_name,
    selected_package_content_description,
  } = giveProductDetails(selectedOptions?.selectedProducts);

  const selectProduct = (e, item) => {
    let sub_part_string = `${item.part_number}@${item.package_content_description}`;
    if (e.target.checked) {
      func(sub_part_string, "add");
      return;
    }
    func(sub_part_string, "remove");
  };
  const filteredSubProducts = attributeList[0]?.sub_product_data.filter(
    (item) =>
      selected_package_content_description?.includes(
        `${item.package_content_description}`
      ) || selected_product_name?.includes(item.package_content_description)
  );
  return attributeList
    ? (exceptionEtailer
        ? attributeList[0].sub_product_data
        : filteredSubProducts
      ).map((item, index) => (
        <div className="conf-apply-emb-blk " key={index}>
          <div className="conf-apply-emb-blk-header">
            {item.package_content_description}
          </div>
          <div className="conf-apply-emb-blk-item">
            <label style={{ padding: "0" }}>
              {exceptionEtailer ? (
                <input
                  type="checkbox"
                  checked={exceptionEtailer}
                  disabled={exceptionEtailer}
                  suppressHydrationWarning
                  onClick={(e) => selectProduct(e, item)}
                />
              ) : (
                <input
                  type="checkbox"
                  onClick={(e) => selectProduct(e, item)}
                />
              )}
              <span style={{ paddingLeft: "15px" }}>
                {" "}
                {item.package_content_description}{" "}
              </span>
            </label>
          </div>
        </div>
      ))
    : null;
};

export default SubProductSelector;

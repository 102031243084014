import React, { useState } from "react";

/**  html parser */
import parse from "html-react-parser";

/** react hooks */
import { useSelector, useDispatch } from "react-redux";

/** checkout and other function handler */
import {
  checkoutCart,
  optionalProduct,
  notification,
  manageAccordians,
} from "./../../../redux/actionCreator/actionCreator";

/** slider component */
import Slider from "../../../components/Slider/Slider";

/** model box to display the options */
import ShowModal from "./modal";

/**
 * render main method list view
 *
 * @param {*} NA
 * @returns
 */
const ListView = () => {
  const {
    productList,
    helper,
    accordian,
    lastApiPayload,
    vehicleUnique,
    yearList,
  } = useSelector((state) => state.dataPack);

  const dispatch = useDispatch();

  /** format list view data structure */
  let dataAnalysis = helper?.listViewDataAnalyzer(productList);

  /** get data by group code */
  let option_group_code = dataAnalysis?.sequence["PPT2"] ? "PPT2" : "PPT1";

  let [partNumber, setPartNumber] = useState(
    dataAnalysis?.sequence[option_group_code]
  );
  let [sequence, setSequence] = useState(dataAnalysis?.sequence["PDD"]);

  let [cart, setCart] = useState("");
  let [sub, setSub] = useState("");

  /** manage product additional details */
  let accordianIds = JSON.parse(accordian);

  /** find product formatted details */
  let productFilterData = helper?.getProductFilterData(
    productList,
    partNumber,
    sequence,
    dataAnalysis
  );

  const isRequired = dataAnalysis?.isRequired;

  return (
    <div className="conf-select-products">
      {renderProductList(productFilterData)}
      <>
        {dataAnalysis?.checkoutGroupFlag && (
          <div className="group_cart">
            {cart && (
              <button
                type="button"
                className="btn conf-btn-primary mar-l10 pull-right"
                data-product=""
                onClick={() => handleCheckout()}
              >
                Add to Cart
              </button>
            )}
            {!cart && (
              <button
                type="button"
                className="btn conf-btn-primary mar-l10 pull-right disabled"
              >
                Add to Cart
              </button>
            )}
          </div>
        )}
      </>
      <form action="" id="postBackForm" method="post"></form>
    </div>
  );

  /**
   * render product list with details
   *
   * @param {object} productFilterData
   * @returns
   */
  function renderProductList(productFilterData) {
    if (productFilterData) {
      return productFilterData?.map((details, index) => {
        let isAccordianTrue = accordianIds?.includes(details?.part_number);

        return (
          <div
            className={`conf-product-blk ${isAccordianTrue ? "" : "active"}`}
            key={index}
          >
            <div className="row clearfix">
              <div className="conf-prod-main-box clearfix">
                {!accordianIds?.includes(details?.part_number) ? (
                  <div className="col-sm-5 pl-0">
                    <div className="image-list-wrapper  ">
                      <div className="config-product-gallery">
                        <div className="gallery-big-img">
                          <div className="img-contain">
                            <div className="img-shiv">
                              <img src={details?.media_list[0]} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Slider showSlider={true} images={details.media_list} />
                )}
                <div className="col-sm-7 pb46">
                  <div className="conf-select-prod-details conf-btn-position-btm">
                    <h4 className="conf-title-3 ">
                      {" "}
                      {details?.package_content_description}
                      <span> - {details?.part_number}</span>
                    </h4>
                    <p className="conf-sel-prod-desc ">
                      {details?.package_content_description_copy}
                    </p>
                  </div>
                  {dataAnalysis?.optionGroupCode &&
                    manageDependentProductFilter(details)}
                </div>

                <div className="row">
                  <div className="col-sm-7 col-sm-offset-5 conf-product-blk-footer text-left clearfix">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 mar-prdct clearfix">
                        <div className="con-mob-left-pnl">
                          <button
                            type="button"
                            className="btn btn-showmore btn-block pull-left"
                            onClick={(el) =>
                              manageAccordian(el, details?.part_number)
                            }
                          >
                            {accordianIds?.includes(details?.part_number) && (
                              <>
                                <span className="icon-plus"> </span>
                                <strong className="showMoreText-436113 ">
                                  Less <br />
                                  DETAILS
                                </strong>
                              </>
                            )}
                            {!accordianIds?.includes(details?.part_number) && (
                              <>
                                <span className="icon-plus"> </span>
                                <strong className="showMoreText-436113 ">
                                  More <br />
                                  DETAILS
                                </strong>
                              </>
                            )}
                          </button>
                        </div>
                        <div className="con-mob-right-pnl">
                          <span className="conf-price ">
                            ${details?.desired_retail_price}{" "}
                          </span>
                          {yearList.length >= 1 ? (
                            <span
                              className="btn-select clearfix active"
                              id="product_selected_LS2"
                            >
                              <label>
                                {" "}
                                <span className="icon-checkbox">
                                  <input
                                    type="checkbox"
                                    className="product_checkbox"
                                    data-id={details?.part_number}
                                    onClick={(el) => {
                                      setCart(el.target.checked ? true : false);
                                    }}
                                  />
                                </span>
                                <span> Select </span>
                              </label>
                            </span>
                          ) : (
                            <button
                              type="button"
                              className="btn conf-btn-primary mar-l10 pull-right"
                              data-product={details?.part_number}
                              onClick={(el) => handleCheckout(el)}
                            >
                              Add to Cart
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`col-xs-12 collapse ${
                  accordianIds?.includes(details?.part_number) ? "in" : ""
                }`}
              >
                <div className="conf-product-more-content">
                  <div className="conf-product-more-feature-text conf-text-btm-border pad-btm-0 ">
                    <h4 className="conf-title-3">Product Features</h4>
                    <ul className="conf-list-normal">
                      <span className="cti-prd-ftr-li ">
                        {renderFeatureList(details)}
                      </span>
                    </ul>
                  </div>
                  <div className="conf-product-more-additional-text pad-btm-0 ">
                    <h4 className="conf-title-3">Additional Information</h4>
                    <p className="cti-addntl-txt ">
                      {details?.long_copy ? parse(details?.long_copy) : null}{" "}
                    </p>
                  </div>
                </div>
              </div>
              {renderAdditionalProductList(details)}
              {!dataAnalysis?.checkoutGroupFlag && yearList.length >= 1 && (
                <div className="group_cart">
                  <button
                    type="button"
                    className="btn conf-btn-primary mar-l10 pull-right"
                    data-product={details?.part_number}
                    onClick={(el) => handleCheckout(el)}
                  >
                    Add to Cart
                  </button>
                </div>
              )}
            </div>
            {details?.option_group_list[0]?.option_group_code ==
              dataAnalysis?.optionGroupCode && (
              <ShowModal
                details={details}
                dataAnalysis={dataAnalysis}
                setPartNumber={setPartNumber}
                option_group_code={option_group_code}
              />
            )}
          </div>
        );
      });
    }
    return false;
  }

  /**
   * manage product dependent data
   *
   * @param {object} details
   * @returns
   */
  function manageDependentProductFilter(details) {
    return (
      <>
        {details?.option_group_list[0]?.option_group_code == "PDD" ? (
          <>
            <select
              className="form-control"
              onChange={(el) => setSequence(el.target.value)}
              value={sequence}
            >
              {renderOptionalData(dataAnalysis, details)}
            </select>
          </>
        ) : (
          <button
            value="button"
            className="btn config-btn-option-grp conf-marb-15 conf-block"
            onClick={() =>
              dispatch(optionalProduct(dataAnalysis?.optionalData))
            }
          >
            {details?.option_group_list[0]?.option_group_button_copy}
            <span className="icon-option-grp"> </span>
          </button>
        )}
      </>
    );
  }

  /**
   * render product optional data
   *
   * @param {object} dataAnalysis
   * @param {object} details
   * @returns
   */
  function renderOptionalData(dataAnalysis, details) {
    if (dataAnalysis?.sequenceGroup) {
      let option_group_code = details?.option_group_list[0]?.option_group_code;

      return dataAnalysis?.sequenceGroup[option_group_code]?.map(
        (option, key) => {
          return (
            <option value={option?.sequence} key={key}>
              {" "}
              {option?.colorName}
            </option>
          );
        }
      );
    }
  }

  /**
   * render additional product details
   *
   * @param {array} details
   * @returns
   */
  function renderAdditionalProductList(details) {
    const isRequired = details?.dependent_product?.required;
    const dependent_product_list = isRequired
      ? productList?.data?.dependent_product_list
      : productList?.data?.dependent_product_list.filter(
          (list) => list?.product_line_code == details?.product_line_code
        );

    if (dependent_product_list) {
      return dependent_product_list?.map((details, index) => {
        let isAccordianTrue = accordianIds?.includes(details?.part_number);

        return (
          <div
            className={`conf-product-blk ${
              isRequired ? "conf-rpt active" : "conf-opt"
            } ${isAccordianTrue ? "" : " active"}`}
            key={index}
          >
            <div className="row clearfix">
              <div className="conf-prod-main-box clearfix">
                {!accordianIds?.includes(details?.part_number) ? (
                  <div className="col-sm-5 pl-0">
                    <div className="image-list-wrapper  ">
                      <div className="config-product-gallery">
                        <div className="gallery-big-img">
                          <div className="img-contain">
                            <div className="img-shiv">
                              <img src={details?.media_list[0]} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Slider showSlider={true} images={details.media_list} />
                )}
                <div className="col-sm-7">
                  <div className="conf-select-prod-details conf-btn-position-btm">
                    <h4 className="conf-title-3 ">
                      {" "}
                      {details?.package_content_description}
                      <span> - {details?.part_number}</span>
                    </h4>
                    <p className="conf-sel-prod-desc ">
                      {parse(details?.package_content_description_copy)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-sm-7 col-sm-offset-5 conf-product-blk-footer text-left clearfix"
                    style={{ marginLeft: "1px!important" }}
                  >
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 mar-prdct clearfix">
                        <div className="con-mob-left-pnl">
                          <button
                            type="button"
                            data-id={details?.part_number}
                            className="btn btn-showmore btn-block pull-left"
                            onClick={(el) => {
                              manageAccordian(el, details?.part_number);
                            }}
                          >
                            {accordianIds?.includes(details?.part_number) && (
                              <>
                                <span className="icon-plus"> </span>
                                <strong className="showMoreText-436113 ">
                                  LESS <br />
                                  DETAILS
                                </strong>
                              </>
                            )}
                            {!accordianIds?.includes(details?.part_number) && (
                              <>
                                <span className="icon-plus"> </span>
                                <strong className="showMoreText-436113 ">
                                  More <br />
                                  DETAILS
                                </strong>
                              </>
                            )}
                          </button>
                        </div>
                        <div className="con-mob-right-pnl">
                          <span className="conf-price ">
                            ${details?.desired_retail_price}{" "}
                          </span>
                          <span
                            className="btn-select clearfix active"
                            id="product_selected_LS2"
                          >
                            <label>
                              {" "}
                              <span className="icon-checkbox">
                                <input
                                  type="checkbox"
                                  className="product_checkbox"
                                  data-product={details?.product_line_code}
                                  data-id={details?.part_number}
                                  data-type={details?.part_number}
                                  onClick={(el) => {
                                    setSub(el.target.checked ? true : false);
                                  }}
                                />
                              </span>
                              <span> Select </span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                collapse="isCollapsed"
                className={`col-xs-12 collapse ${
                  accordianIds?.includes(details?.part_number) ? "in" : ""
                }`}
              >
                <div className="conf-product-more-content">
                  <div className="conf-product-more-feature-text conf-text-btm-border pad-btm-0 ">
                    <h4 className="conf-title-3">Product Features</h4>
                    <ul className="conf-list-normal">
                      <span className="cti-prd-ftr-li ">
                        {renderFeatureList(details)}
                      </span>
                    </ul>
                  </div>
                  <div className="conf-product-more-additional-text pad-btm-0 ">
                    <h4 className="conf-title-3">Additional Information</h4>
                    <p className="cti-addntl-txt ">
                      {details?.long_copy ? parse(details?.long_copy) : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return false;
  }

  /**
   * manage accordian using unique part number
   *
   * @param {object} el
   * @param {string} part_number
   */
  function manageAccordian(el, part_number) {
    let accordianIds = JSON.parse(accordian);
    if (accordianIds?.includes(part_number)) accordianIds?.splice(0, 1);
    else accordianIds?.push(part_number);

    dispatch(manageAccordians(accordianIds));
  }

  /**
   * render feature details
   *
   * @param {array} details
   * @returns
   */
  function renderFeatureList(details) {
    const { feature_list } = details;
    const { feature_text } = Array.isArray(feature_list) ? feature_list[0] : [];

    if (feature_text) {
      return feature_text?.map((option, index) => {
        return <li key={index}>{option}</li>;
      });
    }
  }

  /**
   * handle checkout method
   *
   * @param {object} el
   * @return
   */
  function handleCheckout(el) {
    let productDetail = getSelectedProductPartNumber(el, productFilterData);

    if (!productDetail?.priceList?.length && productDetail?.status)
      notification(
        "No product selected. Please Select a product",
        "Warning",
        "danger"
      );

    let data = {
      year: lastApiPayload?.year,
      make: lastApiPayload?.make,
      make_name: lastApiPayload?.makeName,
      model: lastApiPayload?.modelName,
      base_vehicle_id: lastApiPayload?.base_vehicle_id,
      vehicle_uniques: vehicleUnique,

      part_number_list: productDetail?.partNumberList,
      product_price_list: productDetail?.priceList,
      price_group_code: productDetail?.groupCode,
      snippet_data_sid: window?.Configurator?.etailerPostBackParameters?.snippet_data_sid,
    };
    /** adding to checkout cart */
    dispatch(checkoutCart(data));
  }

  /**
   * get products price and number formatted
   *
   * @param {object} el
   * @param {object} productFilterData
   * @returns
   */
  function getSelectedProductPartNumber(el, productFilterData) {
    let isValidate = true;
    let partNumberList = [];
    let priceList = [];
    let groupCode = 0;
    let subProductDetails = [];

    let selectedProducts = getSelectedItems(el, productFilterData);

    selectedProducts?.map((option) => {
      let productDetails = productFilterData?.filter(
        (list) => list?.part_number == option
      );
      if (productDetails) {
        productDetails?.map((product) => {
          subProductDetails = [];
          groupCode = product?.price_group_code;

          if (
            selectedProducts?.includes(product?.part_number) &&
            !partNumberList?.includes(product?.part_number)
          ) {
            partNumberList.push(product?.part_number);
            priceList.push(
              product?.part_number + "@" + product?.desired_retail_price
            );
          }

          if (product?.dependent_product?.required) {
            product?.dependent_product?.required?.product?.map(
              (requiredProduct) => {
                if (selectedProducts?.includes(requiredProduct)) {
                  subProductDetails = getRequiredProductDetails(
                    productList,
                    product,
                    selectedProducts
                  );
                  subProductDetails?.partNumberList?.map((data, index) => {
                    partNumberList?.push(data);
                    priceList?.push(subProductDetails?.priceList[index]);
                  });
                } else {
                  isValidate = false;
                  notification(
                    product?.package_content_description +
                      " has a required part. Please select required part. ",
                    "Warning",
                    "danger"
                  );
                  return false;
                }
              }
            );
          } else {
            subProductDetails = getOptionalProductDetails(
              productList,
              product,
              selectedProducts
            );
            subProductDetails?.partNumberList?.map((data, index) => {
              partNumberList?.push(data);
              priceList?.push(subProductDetails?.priceList[index]);
            });
          }
        });
      }
    });

    return isValidate
      ? {
          status: true,
          priceList: priceList?.join(","),
          groupCode: groupCode,
          partNumberList: partNumberList?.join(","),
        }
      : {
          status: false,
        };
  }

  /**
   * get all selected items
   *
   * @param {object} el
   * @param {object} productFilterData
   * @returns
   */
  function getSelectedItems(el, productFilterData) {
    let selectedProducts = [];
    let filters = [];

    let inputs = document.querySelectorAll(".product_checkbox:checked");
    let target = el ? el.target.getAttribute("data-product") : false;

    //if there is no yearList then directly add the getAttribute value
    if (yearList.length >= 1) {
      inputs?.forEach((element) => {
        selectedProducts.push(element.getAttribute("data-id"));
      });
    } else {
      selectedProducts = [el.target.dataset.product];
    }
    console.log(selectedProducts);
    let filterProduct = target
      ? productFilterData?.filter((list) => list?.part_number == target)
      : productFilterData;

    filterProduct?.map((options) => {
      if (selectedProducts?.includes(options?.part_number)) {
        filters.push(options?.part_number);

        options?.dependent_product?.optional?.product?.map((number) => {
          if (selectedProducts?.includes(number)) filters.push(number);
        });

        options?.dependent_product?.required?.product?.map((number) => {
          if (selectedProducts?.includes(number)) filters.push(number);
        });
      }
    });
    return filters;
  }
};
/**
 *
 * find optional product list
 *
 * @param {object} productList
 * @param {object} product
 * @param {array} selectedProducts
 * @returns
 */
const getOptionalProductDetails = (productList, product, selectedProducts) => {
  let partNumberList = [];
  let priceList = [];

  const { dependent_product_list } = productList?.data || [];
  product?.dependent_product?.optional?.product?.map((option) => {
    let dependentProduct = dependent_product_list?.filter(
      (list) =>
        list?.product_line_code == product?.product_line_code &&
        list?.part_number == option
    );
    if (dependentProduct) {
      dependentProduct?.map((details) => {
        if (selectedProducts?.includes(details?.part_number)) {
          partNumberList.push(details?.part_number);
          priceList.push(
            details?.part_number + "@" + details?.desired_retail_price
          );
        }
      });
    }
  });

  return { partNumberList, priceList };
};

/**
 *
 * find required product list
 *
 * @param {object} productList
 * @param {object} product
 * @param {array} selectedProducts
 * @returns
 */
const getRequiredProductDetails = (productList, product, selectedProducts) => {
  let partNumberList = [];
  let priceList = [];

  const { dependent_product_list } = productList?.data || [];
  product?.dependent_product?.required?.product?.map((option) => {
    let dependentProduct = dependent_product_list?.filter(
      (list) => list?.part_number == option
    );

    if (dependentProduct) {
      dependentProduct?.map((details) => {
        if (selectedProducts?.includes(details?.part_number)) {
          partNumberList.push(details?.part_number);
          priceList.push(
            details?.part_number + "@" + details?.desired_retail_price
          );
        }
      });
    }
  });

  return { partNumberList, priceList };
};

export default ListView;

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/actions/actionCreators";

/**
 * model box to show the relavent product details
 * 
 * @param {object} props 
 * @returns 
 */
const Modal = (props) => {

    const { details, dataAnalysis, option_group_code } = props

    const optionalData = Array.isArray(details?.option_group_list) ? details?.option_group_list[0] : details?.option_group_list

    const { showProductModal } = useSelector((state) => state.dataPack);
    const dispatch = useDispatch();

    return showProductModal ? (
        <>
            <div className="modal-backdrop fade  in"></div>
            <div
                id="modalClose"
                tabIndex="-1"
                role="dialog"
                className="modal fade in popupModel"
                size="md"
                index="0"
                animate="animate"
                onClick={() => dispatch(closeModal())}
                style={{ display: "block" }}
            >
                <div
                    className="modal-dialog"
                    style={{
                        display: "block",
                        margin: "auto",
                        marginTop: "10px",
                    }}
                >
                    <div className="modal-content">
                        <div className="cofig-tech option-group-popup">
                            <div className="container-fluid">
                                <div className="conf-att-popup">
                                    <div className="conf-att-popup-heading">
                                        <div className="clearfix">
                                            <h4 className="pull-left">
                                                {optionalData?.option_group_name}
                                            </h4>
                                            <span className="pull-right">
                                                <a
                                                    className="conf-close"
                                                    onClick={() => dispatch(closeModal())}
                                                ></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="panel-body-content">
                                        <h4 className="conf-title-2 ng-binding">{optionalData?.option_group_instruction}</h4>
                                        <div className="row clearfix conf-marb-10 conf-color-selection">
                                            <div className="col-xs-12">
                                                {
                                                    renderOptionalData(dataAnalysis, option_group_code)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : null;
    
    /**
     * render product optional details
     * 
     * @param {object} dataAnalysis 
     * @param {array} option_group_code 
     * @returns 
     */
    function renderOptionalData(dataAnalysis, option_group_code) {
        const { sequenceGroup } = dataAnalysis

        return sequenceGroup[option_group_code]?.map((option, index) => {

            return (
                <div className="col-xs-6 col-sm-3" key={index}>
                    <div className="conf-embroid-one">
                        <div className="conf-embroid-head">{option?.color}</div>
                        <div className="conf-embroid-content">
                            <div className="conf-img-container">
                                <img src={option?.media} onClick={() => props?.setPartNumber(option?.sequence)} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }
};


export default Modal;

import { SELECTED_OPTIONS } from "../../../redux/globaltypes";
/**
 *
 *
 * @param {*} product
 * @param {*} note
 * @param {*} selectedOptions
 * @param {*} dispatch
 * @param {*} productUniqueString
 * @return {*}
 */
export const selectProducts = (
  product,
  note,
  selectedOptions,
  dispatch,
  productUniqueString
) => {
  /**first inserting everything in the product object in order to make it easily accessible */

  product.note = note || "";

  product.productUniqueString = productUniqueString || "";

  /**getting the brand application as it is unique for brand_application_code*/
  if (
    selectedOptions?.selectedProducts[product.brand_application_code + product.price_group_code+product.part_number] &&
    selectedOptions?.selectedProductsIdentity?.includes(
      product.brand_application_code + product.price_group_code+product.part_number
    )
  ) {
    let newSelectedProducts = selectedOptions.selectedProducts;
    let newSelectedProductsIdentity =
      selectedOptions.selectedProductsIdentity.filter(
        (item) =>
          item != product.brand_application_code + product.price_group_code+product.part_number
      );
    delete newSelectedProducts[product.brand_application_code + product.price_group_code+product.part_number];
    dispatch({
      type: SELECTED_OPTIONS,
      payload: {
        ...selectedOptions,
        selectedProducts: {
          ...newSelectedProducts,
        },

        selectedProductsIdentity: [...newSelectedProductsIdentity],
      },
    });
    return;
  }
  dispatch({
    type: SELECTED_OPTIONS,
    payload: {
      ...selectedOptions,
      selectedProducts: {
        ...selectedOptions?.selectedProducts,
        [product.brand_application_code + product.price_group_code+product.part_number]: product,
      },
      selectedProductsIdentity: [
        ...selectedOptions?.selectedProductsIdentity,
        product.brand_application_code + product.price_group_code+product.part_number,
      ],
    },
  });
};
